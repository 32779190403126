export default function documentDisplay(cpfcnpj) {
  if (!cpfcnpj) return "";

  return cpfcnpj.length > 11
    ? cpfcnpj.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2})/,
        "$1.$2.$3/$4-$5"
      )
    : cpfcnpj.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/, "$1.$2.$3-$4");
}
