import Decimal from "decimal.js";
import React from "react";
import { useSelector } from "react-redux";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

const TIME_LIMIT_IN_SEC = 30;
const TIMESTAMP_LIMIT = TIME_LIMIT_IN_SEC * 1000;

function calcRemainingTime(sendCodeTimeStamp) {
  const timeElapsed = Date.now() - sendCodeTimeStamp;
  const time = Decimal(TIMESTAMP_LIMIT).minus(timeElapsed);
  return time.dividedBy(1000);
}

export default function SendCodeWarning({
  showWarning,
  setShowWarning,
  resend,
  ...props
}) {
  const sendCodeTimeStamp = useSelector(
    (state) => state.user.sendCodeTimeStamp
  );
  const [remainingTime, setRemainingTime] = React.useState(
    calcRemainingTime(sendCodeTimeStamp)
  );

  React.useEffect(() => {
    const codeTimeout = setTimeout(() => {
      const newRemainingTime = calcRemainingTime(sendCodeTimeStamp);
      if (newRemainingTime > 0) {
        setShowWarning(true);
        setRemainingTime(newRemainingTime);
      } else {
        setShowWarning(false);
      }
    }, 1000);

    return () => clearTimeout(codeTimeout);
  }, [remainingTime, sendCodeTimeStamp, setShowWarning]);

  if (showWarning && remainingTime.greaterThan(0)) {
    return (
      <div className="text-center mt-5" {...props}>
        <AccessTimeIcon />
        <small>{` Reenviar código em ${remainingTime.round()}...`}</small>
      </div>
    );
  }

  if (resend !== undefined) return resend;

  return null;
}
