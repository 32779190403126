export default function useWorkingTime(shop) {
  const {
    emergencyPause,
    workingScheduleInloco,
    workingScheduleDelivery,
    workingScheduleToGo,
  } = shop;

  const pauseUntil = emergencyPause ? new Date(emergencyPause) : null;
  const pauseOrders = pauseUntil && new Date() < pauseUntil;

  const rulesDelivery = workingScheduleDelivery;
  const rulesToGo = workingScheduleToGo;
  const rulesInloco = workingScheduleInloco;

  const now = new Date();
  const currentWeekDay = now.getDay().toString();
  const todayRulesDelivery = rulesDelivery[currentWeekDay];
  const todayRulesToGo = rulesToGo[currentWeekDay];
  const todayRulesInloco = rulesInloco[currentWeekDay];

  const isWorkingDelivery =
    todayRulesDelivery && !pauseOrders
      ? todayRulesDelivery.map((r) => isWorkingByTime(now, r)).find((i) => i)
      : false;
  const isWorkingToGo =
    todayRulesToGo && !pauseOrders
      ? todayRulesToGo.map((r) => isWorkingByTime(now, r)).find((i) => i)
      : false;
  const isWorkingInloco =
    todayRulesInloco && !pauseOrders
      ? todayRulesInloco.map((r) => isWorkingByTime(now, r)).find((i) => i)
      : false;

  const isWorking = isWorkingDelivery || isWorkingToGo || isWorkingInloco;
  return {
    currentWeekDay,
    rulesDelivery,
    rulesToGo,
    rulesInloco,
    isWorkingDelivery,
    isWorkingToGo,
    isWorkingInloco,
    isWorking,
  };
}

export function getProductAvailability(product, now = new Date()) {
  const currentWeekDay = now.getDay().toString();
  const rules = product.availabilities;
  const todayRules = rules[currentWeekDay];
  const isAvailable =
    product.availabilities && Object.keys(product.availabilities).length > 0
      ? todayRules &&
        todayRules.map((r) => isWorkingByTime(now, r)).find((i) => i)
      : true;

  return {
    rules,
    currentWeekDay,
    isAvailable,
  };
}

export function getGroupAvailability(group, now = new Date()) {
  const currentWeekDay = now.getDay().toString();
  const rules = group.availabilities;
  const todayRules = rules[currentWeekDay];
  const isAvailable =
    group.availabilities && Object.keys(group.availabilities).length > 0
      ? todayRules &&
        todayRules.map((r) => isWorkingByTime(now, r)).find((i) => i)
      : true;
  return {
    rules,
    currentWeekDay,
    isAvailable,
  };
}

const isWorkingByTime = (todayDate, todayRule) => {
  const currentHours = todayDate.getHours();
  const currentMinutes = todayDate.getMinutes();

  if (
    currentHours < todayRule.start.hours ||
    currentHours > todayRule.end.hours
  ) {
    return false;
  }

  if (
    currentHours === todayRule.start.hours &&
    currentMinutes < todayRule.start.minutes
  ) {
    return false;
  }

  if (
    currentHours === todayRule.end.hours &&
    currentMinutes > todayRule.end.minutes
  ) {
    return false;
  }

  return true;
};
