import { useHistory, useRouteMatch } from "react-router-dom";
import get from "lodash.get";

export default function useGoTo() {
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const inLocoRoute =
    get(routeMatch.params, "orderLocation") && get(routeMatch.params, "mesaId");
  if (inLocoRoute) {
    return (endpoint = "/") =>
      history.push(
        `/${routeMatch.params.namespace}/${routeMatch.params.orderLocation}/${routeMatch.params.mesaId}${endpoint}`
      );
  }
  return (endpoint = "/") =>
    history.push(`/${routeMatch.params.namespace}${endpoint}`);
}
