import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  CreditCardNumberInput,
  CreditCardValidInput,
  CreditCardCVVInput,
  CPFCNPJInput,
} from "../components/NumberInput";
import { isValid as isValidCPF } from "cpf";
import { validate as isValidCNPJ } from "cnpj";
import useToast from "../hooks/useToast";
import { addCard } from "../ducks/checkoutSlice";

const styles = {
  input: {
    marginBottom: 15,
  },
};

export default function MercadoPago() {
  const dispatch = useDispatch();
  const { toastError } = useToast();

  const [loading, setLoading] = React.useState(false);
  const [cardNumber, setCardNumber] = React.useState("");
  const [valid, setValid] = React.useState("");
  const [cvv, setCVV] = React.useState("");
  const [nome, setNome] = React.useState("");
  const [cpfCNPJ, setCpfCNPJ] = React.useState("");
  const [issuerList, setIssueList] = React.useState([]);

  const [cardNumberError, setCardNumberError] = React.useState("");
  const [validError, setValidError] = React.useState("");
  const [cvvError, setCVVError] = React.useState("");
  const [nomeError, setNomeError] = React.useState("");
  const [cpfCNPJError, setCpfCNPJError] = React.useState("");

  // const { email } = useSelector((state) => state.checkout);
  // const emailError = validateEmail(email) ? "" : "Email inválido";

  const submitDisabled =
    // emailError ||
    cardNumberError ||
    validError ||
    cvvError ||
    nomeError ||
    cpfCNPJError;

  useEffect(() => {
    if (cardNumber.length < 6) {
      return;
    }

    const bin = cardNumber.substring(0, 6);
    window.mercadoPago
      .getPaymentMethods({ bin })
      .then((response) => setIssueList(response.results));
  }, [cardNumber]);

  const onCardNumberChange = (event) => {
    const value = event.target.value;
    setCardNumber(value);
    setCardNumberError(
      value && value.length === 16
        ? ""
        : "Insira um número válido de 16 dígitos"
    );
  };

  const onValidChange = (event) => {
    const value = event.target.value;
    setValid(value);
    const mes = value.length > 1 ? value.substring(0, 2) : "";
    const ano = value.length === 4 ? value.substring(2, 4) : "";
    const anoHoje = new Date().getFullYear().toString().substring(2, 4);
    setValidError(mes > 0 && mes < 13 && ano >= anoHoje ? "" : "Data inválida");
  };

  const onCVVChange = (event) => {
    const value = event.target.value;
    setCVV(value);
    setCVVError(value.length > 2 ? "" : "Insira um CVV de 3 ou 4 dígitos");
  };

  const onNomeChange = (event) => {
    const value = event.target.value;
    setNome(value);
    const nomes = value
      .split(" ")
      .reduce((acc, n) => acc + (n.length > 0 ? 1 : 0), 0);
    setNomeError(nomes > 1 ? "" : "Insira nome e sobrenome");
  };

  const onCpfCNPJChange = (event) => {
    const value = event.target.value;
    setCpfCNPJ(value);
    setCpfCNPJError(
      isValidCPF(value) || isValidCNPJ(value)
        ? ""
        : "Insira um CPF ou CNPJ válido"
    );
  };

  // const onEmailChange = (event) => {
  //   dispatch(
  //     setFormAnswer({
  //       name: "email",
  //       value: event.target.value,
  //     })
  //   );
  // };

  // const validateCard = async (token) => {
  //   const body = {
  //     email,
  //     token,
  //   };
  //   const response = await fetch(`${API_PALLAS_APPS_URL}/validarCartao`, {
  //     method: "post",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(body),
  //   });

  //   const json = await response.json();
  //   if (!response.ok) {
  //     console.log(json);
  //     throw new Error("Erro ao validar cartão.");
  //   }
  // };

  const onSubmitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const card = {
        cardNumber,
        cardholderName: nome,
        identificationType: cpfCNPJ.length > 11 ? "CNPJ" : "CPF",
        identificationNumber: cpfCNPJ,
        securityCode: cvv,
        cardExpirationMonth: valid.substring(0, 2),
        cardExpirationYear: valid.substring(2, 4),
      };
      const token = await window.mercadoPago.createCardToken(card);

      // await validateCard(token);

      dispatch(
        addCard({
          token,
          card,
          issuerList,
        })
      );
    } catch (err) {
      toastError(
        "Erro ao adicionar o cartão. Revise os dados e tente novamente.",
        { autoClose: 6000 }
      );
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={onSubmitForm}>
      {/* <Alert variant="info" className="text-center">
        <small className="text-muted">
          Faremos uma cobrança com devolução automática
        </small>
      </Alert>
      <TextField
        fullWidth={true}
        style={styles.input}
        variant="outlined"
        type="text"
        label="Email"
        error={!!emailError}
        helperText={emailError}
        value={email}
        onChange={onEmailChange}
        maxLength={64}
        required
      /> */}
      <TextField
        fullWidth={true}
        style={styles.input}
        variant="outlined"
        type="text"
        label="Número do cartão"
        required
        onChange={onCardNumberChange}
        value={cardNumber}
        error={!!cardNumberError}
        helperText={cardNumberError}
        InputProps={{
          inputComponent: CreditCardNumberInput,
          endAdornment:
            issuerList.length > 0 ? (
              <InputAdornment position="start">
                <img src={issuerList[0].thumbnail} alt="" />
              </InputAdornment>
            ) : null,
        }}
      />

      <Row>
        <Col xs="6">
          <TextField
            fullWidth={true}
            style={styles.input}
            variant="outlined"
            type="text"
            label="Validade"
            required
            onChange={onValidChange}
            value={valid}
            error={!!validError}
            helperText={validError}
            InputProps={{
              inputComponent: CreditCardValidInput,
            }}
          />
        </Col>
        <Col xs="6">
          <TextField
            fullWidth={true}
            style={styles.input}
            variant="outlined"
            type="text"
            label="CVV"
            required
            onChange={onCVVChange}
            value={cvv}
            error={!!cvvError}
            helperText={cvvError}
            InputProps={{
              inputComponent: CreditCardCVVInput,
            }}
          />
        </Col>
      </Row>

      <TextField
        fullWidth={true}
        style={styles.input}
        variant="outlined"
        type="text"
        label="Nome do titular"
        required
        onChange={onNomeChange}
        value={nome}
        error={!!nomeError}
        helperText={nomeError}
      />

      <TextField
        fullWidth={true}
        style={styles.input}
        variant="outlined"
        type="text"
        label="CPF ou CNPJ do titular"
        required
        onChange={onCpfCNPJChange}
        value={cpfCNPJ}
        error={!!cpfCNPJError}
        helperText={cpfCNPJError}
        InputProps={{
          inputComponent: CPFCNPJInput,
        }}
      />

      <Button
        variant="danger"
        className="w-100 p-2"
        type="submit"
        disabled={submitDisabled}
      >
        {loading && <Spinner animation="border" size="sm" className="mr-2" />}
        {!loading && "Salvar cartão"}
      </Button>
    </Form>
  );
}
