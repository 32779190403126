import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Decimal from "decimal.js";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import TextField from "@material-ui/core/TextField";
import PaymentIcon from "@material-ui/icons/Payment";
import DialpadIcon from "@material-ui/icons/Dialpad";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import { MoneyInput } from "../components/NumberInput";
import toPriceDisplay from "../utils/toPriceDisplay";
import {
  setFormAnswer,
  setPaymentOnline,
  setPaymentOffline,
  setPayment,
  setCash,
  selectCard,
  removeCard,
  clearPayment,
  selectStatefulCosts,
  PIX_OPTION,
  MERCADO_PAGO_OPTION,
} from "../ducks/checkoutSlice";
import PaymentCardImage from "../components/PaymentCardImage";
import pixURL from "../assets/payment-flat-rounded/pix.png";
import mpURL from "../assets/payment-flat-rounded/mercado-pago.svg";
import CardMercadoPago from "./CardMercadoPago";

const styles = {
  body: {
    margin: 10,
  },
  input: {
    marginTop: 30,
  },
  cover: {
    backgroundColor: "rgb(57, 158, 227)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    marginBottom: 16,
  },
  image: {
    padding: 10,
  },
};

export default function PaymentSelection() {
  const dispatch = useDispatch();
  const [cardToDelete, setCardToDelete] = React.useState(null);
  const costs = useSelector(selectStatefulCosts);
  const {
    toGo,
    paymentOnline,
    paymentOffline,
    payment,
    cardSelected,
    isPaymentListVisible,
    isCashFormVisible,
    isCardFormVisible,
    cash,
    cards,
  } = useSelector((state) => state.checkout);

  const {
    availablePaymentsToGo,
    availablePaymentsDelivery,
    mercadoPagoLogin,
    mercadoPagoCartao,
    mercadoPagoPix,
  } = useSelector((state) => state.shop);

  const mercadoPagoEnable =
    mercadoPagoLogin || mercadoPagoCartao || mercadoPagoPix;
  const availablePaymentsOff = toGo
    ? availablePaymentsToGo
    : availablePaymentsDelivery;

  const paymentOfflineLabel = toGo ? "Pagar na loja" : "Pagar na entrega";

  useEffect(() => {
    if (!mercadoPagoEnable && !paymentOffline) {
      dispatch(clearPayment());
      dispatch(setPaymentOffline());
      return;
    }
    if (
      mercadoPagoEnable &&
      paymentOffline &&
      availablePaymentsOff.length === 0
    ) {
      dispatch(clearPayment());
      dispatch(setPaymentOnline());
      return;
    }

    if (payment) {
      if (
        !(mercadoPagoEnable && mercadoPagoPix && PIX_OPTION.name === payment) &&
        !(
          mercadoPagoEnable &&
          mercadoPagoLogin &&
          MERCADO_PAGO_OPTION.name === payment
        ) &&
        !(mercadoPagoEnable && mercadoPagoCartao && cardSelected) &&
        !availablePaymentsOff.find((p) => p.name === payment)
      ) {
        dispatch(clearPayment());
      }
    }
  }, [
    mercadoPagoEnable,
    mercadoPagoLogin,
    mercadoPagoCartao,
    mercadoPagoPix,
    paymentOffline,
    availablePaymentsOff,
    payment,
    cardSelected,
    dispatch,
  ]);

  const onToChangeClick = () => {
    dispatch(setFormAnswer({ name: "isPaymentListVisible", value: true }));
  };

  const onPaymentSelect = (selected) => {
    dispatch(setPayment(selected));
  };

  const cancelSelection = () => {
    dispatch(setFormAnswer({ name: "isPaymentListVisible", value: false }));
  };

  const onChangeForCashChange = (event) => {
    const value = event.target.value;
    if (value.length > 0) {
      dispatch(setFormAnswer({ name: "cash", value }));
    }
  };

  const onCashSubmit = (event) => {
    event.preventDefault();
    dispatch(setCash({ cash }));
  };

  const cancelCashForm = () => {
    dispatch(clearPayment());
  };

  const cancelCardForm = () => {
    dispatch(setFormAnswer({ name: "isCardFormVisible", value: false }));
  };

  const onCardSelection = (card) => {
    dispatch(selectCard(card));
  };

  const onRemoveCard = () => {
    dispatch(removeCard(cardToDelete));
    setCardToDelete(null);
  };

  const setNeedChangeFalse = () => {
    const value = Decimal(costs.total).times(100).toString();
    dispatch(setCash({ cash: value }));
  };

  const addNewCard = () => {
    dispatch(setFormAnswer({ name: "isPaymentListVisible", value: false }));
    dispatch(setFormAnswer({ name: "isCardFormVisible", value: true }));
  };

  const setOnline = () => {
    dispatch(setPaymentOnline());
  };

  const setOffline = () => {
    dispatch(setPaymentOffline());
  };

  const showDeleteCard = (event, card) => {
    event.stopPropagation();
    setCardToDelete(card);
  };

  const cancelDeleteCard = () => {
    setCardToDelete(null);
  };

  const isCashSelected = costs.paymentIsCash && !isCashFormVisible;
  const error = costs.paymentError !== null;

  return (
    <div className="p-3">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-column">
          <p className="m-0 font-weight-bold">Forma de pagamento</p>
          {payment && (
            <p className="mt-1 mb-0 text-muted">
              <small>
                {isCashSelected
                  ? `${payment} ${toPriceDisplay(costs.paymentValue)}`
                  : payment}
              </small>
            </p>
          )}
          {isCashSelected && costs.paymentError && (
            <p className={"mt-1 mb-0 text-danger"}>
              <small>{costs.paymentError}</small>
            </p>
          )}
          {isCashSelected && !costs.paymentError && (
            <p className={"mt-1 mb-0 text-muted"}>
              <small>
                {Decimal(costs.paymentDiff).equals(0)
                  ? "Sem troco"
                  : `Troco ${toPriceDisplay(costs.paymentDiff)}`}
              </small>
            </p>
          )}
        </div>
        <div>
          {payment && (
            <PaymentCardImage paymentLabel={payment} className="mr-2" />
          )}
          <Button variant="link" onClick={onToChangeClick}>
            {payment ? "Trocar" : "Escolher"}
          </Button>
        </div>
      </div>

      <Modal
        show={isPaymentListVisible && !cardToDelete}
        onHide={cancelSelection}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h3">Forma de pagamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {availablePaymentsOff.length > 0 && mercadoPagoEnable && (
            <div className="pb-3 mb-3 d-flex justify-content-between">
              <Button
                className="w-100 mr-3 p-2"
                variant={paymentOnline ? "danger" : "outline-danger"}
                onClick={setOnline}
              >
                <PaymentIcon className="mr-2" />
                Pagar pelo app
              </Button>
              <Button
                className="w-100 ml-3 p-2"
                variant={paymentOffline ? "danger" : "outline-danger"}
                onClick={setOffline}
              >
                <DialpadIcon className="mr-2" />
                {paymentOfflineLabel}
              </Button>
            </div>
          )}

          {mercadoPagoEnable && paymentOnline && (
            <>
              <ListGroup style={{ minHeight: "300px" }}>
                {mercadoPagoLogin && (
                  <ListGroup.Item
                    href="#"
                    key="mercado_pago"
                    onClick={() => onPaymentSelect(MERCADO_PAGO_OPTION)}
                    className="mt-3 d-flex flex-row align-items-center justify-content-between cursor-pointer"
                  >
                    <img src={mpURL} alt="Mercado Pago" height="48" />
                    {MERCADO_PAGO_OPTION.name}
                  </ListGroup.Item>
                )}
                {mercadoPagoPix && (
                  <ListGroup.Item
                    href="#"
                    key="pix"
                    onClick={() => onPaymentSelect(PIX_OPTION)}
                    className="mt-3 d-flex flex-row align-items-center justify-content-between cursor-pointer"
                  >
                    <img
                      src={pixURL}
                      alt="PIX"
                      height="48"
                      className="pt-2 pb-2"
                    />
                    {PIX_OPTION.name}
                  </ListGroup.Item>
                )}
                {mercadoPagoCartao &&
                  cards.map((card) => (
                    <ListGroup.Item
                      href="#"
                      key={card.id}
                      onClick={() => onCardSelection(card)}
                      className="mt-3 d-flex flex-row align-items-center justify-content-between cursor-pointer"
                    >
                      <div className="d-flex flex-row align-items-center">
                        <PaymentCardImage
                          paymentLabel={card.issuer_name}
                          className="mr-4"
                        />
                        <div>
                          {card.issuer_name}
                          <br />
                          {`**** ${card.last_four_digits}`}
                        </div>
                      </div>
                      <Button
                        variant="link"
                        onClick={(event) => showDeleteCard(event, card)}
                      >
                        <DeleteForeverIcon />
                      </Button>
                    </ListGroup.Item>
                  ))}
              </ListGroup>
              {mercadoPagoCartao && (
                <Button
                  className="w-100 mt-3"
                  variant="danger"
                  type="submit"
                  onClick={addNewCard}
                >
                  Adicionar novo cartão
                </Button>
              )}
            </>
          )}

          {paymentOffline && (
            <ListGroup>
              {availablePaymentsOff.map((availablePayment) => (
                <ListGroup.Item
                  href="#"
                  key={availablePayment.id}
                  onClick={() => onPaymentSelect(availablePayment)}
                  className="d-flex flex-row align-items-center justify-content-between cursor-pointer"
                >
                  <PaymentCardImage paymentLabel={availablePayment.name} />
                  {availablePayment.name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={isCashFormVisible} onHide={cancelCashForm}>
        <Modal.Header closeButton>
          <Modal.Title as="h3">Precisa de troco?</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onCashSubmit}>
          <Modal.Body style={styles.body}>
            <Button
              variant="danger"
              className="w-100"
              onClick={setNeedChangeFalse}
            >
              Sem troco, tenho o valor exato
              <br />
              {toPriceDisplay(costs.total)}
            </Button>

            <TextField
              fullWidth={true}
              style={styles.input}
              label="Troco para quanto?"
              error={error}
              helperText={costs.paymentError}
              variant="outlined"
              value={cash}
              onChange={onChangeForCashChange}
              InputProps={{
                inputComponent: MoneyInput,
              }}
              required
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={cancelCashForm}>
              Cancelar
            </Button>
            <Button variant="danger" type="submit" disabled={error}>
              Confirmar troco
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={isCardFormVisible} onHide={cancelCardForm}>
        <Modal.Header closeButton>
          <Modal.Title as="h3">Adicionar cartão de crédito</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CardMercadoPago onSuccess={cancelCardForm} />
        </Modal.Body>
      </Modal>

      <Modal show={!!cardToDelete} onHide={cancelDeleteCard}>
        <Modal.Header closeButton>
          <Modal.Title>Quer excluir este cartão?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {cardToDelete &&
            `Ao confirmar, o cartão ${cardToDelete.issuer_name} final ${cardToDelete.last_four_digits} não estará mais disponível na sua lista de cartões salvos no aplicativo.`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={cancelDeleteCard}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={onRemoveCard}>
            Excluir cartão
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
