import React from "react";
import { Form, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import CommonHeader from "../containers/CommonHeader";
import { PhoneInput } from "../components/NumberInput";
import useGoTo from "../hooks/useGoTo";
import { validatePhoneNumber } from "../utils/validate";
import { useDispatch, useSelector } from "react-redux";
import useToast from "../hooks/useToast";
import { loginUser } from "../services/fidelity";
import { setUserPhone, setUserId } from "../ducks/userSlice";
import SendCodeWarning from "../components/SendCodeWarning";

export default function LoginScreen() {
  const dispatch = useDispatch();
  const toast = useToast();
  const { userPhone } = useSelector((state) => state.user);
  const [isLoading, setLoading] = React.useState(false);
  const [showCodeWaring, setShowCodeWarning] = React.useState(true);
  const goTo = useGoTo();

  const onChangePhone = (event) => {
    dispatch(setUserPhone({ phone: event.target.value }));
  };

  const login = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await loginUser(userPhone).then((resp) => resp.json());

      if (response.error) {
        if (response.error.statusCode === 422) {
          goTo("/register");
          return;
        }

        throw new Error(response.error?.message ?? "Something wrong happened");
      }

      dispatch(
        setUserId({ id: response.id, sendCodeChannel: response.channel })
      );
      goTo("/confirmation");
    } catch (error) {
      toast.toastError(
        "Erro ao realizar no envio do código. Por favor, tente novamente."
      );
    } finally {
      setLoading(false);
    }
  };

  const phoneError = validatePhoneNumber(userPhone) ? "" : "Celular inválido";

  const isLoginDisabled = !!phoneError || isLoading || showCodeWaring;

  return (
    <>
      <CommonHeader text="Entre ou cadastre-se" />
      <div className="mt-3 text-center">
        <strong>Informe seu celular com DDD</strong>
      </div>
      <Form className="mt-4 px-3 px-lg-0" onSubmit={login}>
        <TextField
          className="mt-3"
          autoFocus
          fullWidth={true}
          variant="outlined"
          label=""
          value={userPhone}
          onChange={onChangePhone}
          error={!!phoneError}
          helperText={phoneError}
          InputProps={{
            inputComponent: PhoneInput,
          }}
          required
          disabled={isLoading}
        />
        <div className="mt-3 text-center">
          <small className="text-muted">
            Seu cadastro é válido em qualquer restaurante com Delivery ou QR
            Code do Sistema Pallas.
          </small>
        </div>
        <div className="p-3 fixed-bottom">
          <SendCodeWarning
            showWarning={showCodeWaring}
            setShowWarning={setShowCodeWarning}
          />
          <Button
            type="submit"
            className="w-100 mt-3"
            variant="danger"
            disabled={isLoginDisabled}
          >
            {"Continuar"}
          </Button>
        </div>
      </Form>
    </>
  );
}
