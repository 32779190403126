import React from "react";
import aleloURL from "../assets/payment-flat-rounded/alelo.svg";
import amexURL from "../assets/payment-flat-rounded/amex.svg";
import sodexoURL from "../assets/payment-flat-rounded/sodexo.svg";
import ticketURL from "../assets/payment-flat-rounded/ticket.svg";
import verdecardURL from "../assets/payment-flat-rounded/verdecard.svg";
import vrURL from "../assets/payment-flat-rounded/vr.svg";

import posURL from "../assets/payment-flat-rounded/pos-stone.jpg";
import pixURL from "../assets/payment-flat-rounded/pix.png";
import dinheiroURL from "../assets/payment-flat-rounded/dinheiro.svg";
import mercadoPagoURL from "../assets/payment-flat-rounded/mercado-pago.svg";

import visaURL from "../assets/payment-flat-rounded/visa.svg";
import mastercardURL from "../assets/payment-flat-rounded/mastercard.svg";
import hipercardURL from "../assets/payment-flat-rounded/hipercard.svg";
import eloURL from "../assets/payment-flat-rounded/elo.svg";
import genericURL from "../assets/payment-flat-rounded/generic.svg";
import benURL from "../assets/payment-flat-rounded/ben.svg";

export default function PaymentCardImage({ paymentLabel, ...props }) {
  return <img src={getURL(paymentLabel)} alt="cartão" height="24" {...props} />;
}

const getURL = (paymentLabel) => {
  const fullLabel = paymentLabel.toLowerCase();
  const match = (part) => fullLabel.includes(part);

  if (match("alelo")) {
    return aleloURL;
  } else if (match("ben")) {
    return benURL;
  } else if (match("amex")) {
    return amexURL;
  } else if (match("sodexo")) {
    return sodexoURL;
  } else if (match("ticket")) {
    return ticketURL;
  } else if (match("verdecard")) {
    return verdecardURL;
  } else if (match("vr")) {
    return vrURL;
  } else if (match("maquininha")) {
    return posURL;
  } else if (match("pix")) {
    return pixURL;
  } else if (match("dinheiro")) {
    return dinheiroURL;
  } else if (match("mercado")) {
    return mercadoPagoURL;
  } else if (match("visa")) {
    return visaURL;
  } else if (match("mastercard")) {
    return mastercardURL;
  } else if (match("hipercard")) {
    return hipercardURL;
  } else if (match("elo")) {
    return eloURL;
  } else {
    return genericURL;
  }
};
