import React from "react";
import { WEEK_DAYS_PT } from "../ducks/consts";

export default function WorkingTime({ label, rules, currentWeekDay }) {
  const workingDays = Object.keys(rules);

  if (workingDays.length === 0) {
    return null;
  }

  return (
    <>
      <h3 className='pt-3 text-muted m-0'>{label}</h3>
      <table>
        <tbody>
          {workingDays.map((weekDay) => (
            <tr
              key={weekDay}
              className={weekDay === currentWeekDay ? "font-weight-bold" : null}
            >
              <td>{WEEK_DAYS_PT[weekDay]}:</td>
              <td>
                {rules[weekDay]
                  .map(
                    (workingRule) =>
                      " " +
                      workingRule.start.hours.toString().padStart(2, "0") +
                      ":" +
                      workingRule.start.minutes.toString().padStart(2, "0") +
                      " às " +
                      workingRule.end.hours.toString().padStart(2, "0") +
                      ":" +
                      workingRule.end.minutes.toString().padStart(2, "0")
                  )
                  .join(" / ")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
