import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export default function Loading({message}) {
  return (
    <div className="mt-4 d-flex flex-column w-100 align-items-center justify-content-center">
      <Spinner animation="grow" variant="danger" />
      <p className="mt-4">{message ? message : 'Carregando...'}</p>
    </div>
  );
}
