import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import CommonHeader from "../containers/CommonHeader";
import ProductWithQuestions from "../containers/ProductWithQuestions";

export default function ProductScreen() {
  const routeMatch = useRouteMatch();
  const orderUuidEdit = routeMatch.params.orderUuid;
  const productId = parseInt(routeMatch.params.productId, 10);
  const product = useSelector((state) =>
    state.products.items.find((p) => p.id === productId)
  );

  if (!product) {
    return (
      <>
        <CommonHeader text="Detalhes do produto" />
        <Container className="text-center mt-3 mb-3">
          <h1>Ops...</h1>
          <p>Este produto não existe mais.</p>
        </Container>
      </>
    );
  }

  return (
    <ProductWithQuestions
      productId={productId}
      product={product}
      orderUuidEdit={orderUuidEdit}
    />
  );
}
