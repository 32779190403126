import { API_PALLAS_APPS_URL } from "../ducks/consts";

export async function registerUser(name, phone, birthday) {
  return await fetch(`${API_PALLAS_APPS_URL}/user/sendCode`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      nome: name,
      telefone: phone,
      nascido_em: birthday,
    }),
  });
}

export async function loginUser(phone) {
  return await fetch(`${API_PALLAS_APPS_URL}/user/sendCode`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      telefone: phone,
    }),
  });
}

export async function confirmLogin(userId, code) {
  return await fetch(`${API_PALLAS_APPS_URL}/user/login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      appUserId: userId,
      code,
    }),
  });
}

export async function getValidOrders(userId, userUuid, storeId) {
  return await fetch(`${API_PALLAS_APPS_URL}/user/points`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      appUserId: userId,
      uuid: userUuid,
      lojaId: storeId,
    }),
  });
}
