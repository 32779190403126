import React from "react";
import { useSelector } from "react-redux";

export default function ShopFooter() {
  const informationFooter = useSelector(
    (state) => state.shop.informationFooter
  );

  return (
    <footer lang="en" className="shop-footer mt-5">
      <div
        className="w-100 text-center"
        dangerouslySetInnerHTML={{ __html: informationFooter }}
      />
      <p className="mt-5 w-100 text-center">
        <small>
          {"Copyright © 2020~2023 "}
          <a href="https://sistemapallas.com.br" target="blank">
            Sistema Pallas
          </a>
        </small>
      </p>
    </footer>
  );
}
