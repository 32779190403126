import { Button } from "react-bootstrap";
import useQueryParams from "../hooks/useQueryParams";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useEffect, useState } from "react";

export default function ScrollButton({
  elementId,
  top = 30,
  bottom = 30,
  right = 10,
}) {
  const { showScroll } = useQueryParams();
  const [hasScroll, setHasScroll] = useState(false);
  const step = 150;

  useEffect(() => {
    setTimeout(() => {
      const scrollingElement = window.document.getElementById(elementId);
      if (!scrollingElement) return;
      const hasVerticalScrollbar =
        scrollingElement.scrollHeight > scrollingElement.clientHeight;
      setHasScroll(hasVerticalScrollbar);
    }, 600);
  });

  if (!showScroll || !hasScroll) return null;

  const handleClickUp = () => {
    const scrollingElement = window.document.getElementById(elementId);
    const top = scrollingElement.scrollTop;
    scrollingElement.scrollTo({ top: top - step, behavior: "smooth" });
  };

  const handleClickDown = () => {
    const scrollingElement = window.document.getElementById(elementId);
    const top = scrollingElement.scrollTop;
    scrollingElement.scrollTo({ top: top + step, behavior: "smooth" });
  };

  return (
    <>
      <Button
        variant="dark"
        className="btn-scroll-up"
        style={{ top, right }}
        onClick={handleClickUp}
      >
        <ArrowUpwardIcon />
      </Button>

      <Button
        variant="dark"
        className="btn-scroll-down"
        style={{ bottom, right }}
        onClick={handleClickDown}
      >
        <ArrowDownwardIcon />
      </Button>
    </>
  );
}
