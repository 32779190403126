import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import EventIcon from "@material-ui/icons/Event";
import React from "react";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import OrderSchedulerModal from "../components/OrderSchedulerModal";
import SchedulerDay from "../components/SchedulerDay";

const generateSchedulingText = (orderScheduling) => {
  const selectedDate = new Date(orderScheduling.schedule.date);
  const today = selectedDate.getDate() === new Date().getDate();
  const weekday = today
    ? "Hoje,"
    : selectedDate.toLocaleDateString("pt-BR", {
        weekday: "short",
      });

  return `${weekday} ${orderScheduling.schedule.hoursInterval}`;
};

export default function OrderScheduler() {
  const orderScheduling = useSelector(
    (state) => state.checkout.orderScheduling
  );

  const [isSchedulerOpen, setSchedulerOpen] = React.useState(false);

  const openScheduler = () => setSchedulerOpen(true);
  const closeScheduler = () => setSchedulerOpen(false);

  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center p-3">
        <div className="d-flex flex-column">
          <p className="font-weight-bold m-0 text-capitalize">
            {orderScheduling.isScheduledOrder
              ? generateSchedulingText(orderScheduling)
              : "Pedido para agora"}
          </p>
          {orderScheduling.isScheduledOrder && (
            <span className="mt-1 mb-0 text-muted">
              <small>Pedido Agendado</small>
            </span>
          )}
        </div>
        <div>
          <Button variant="link" onClick={openScheduler}>
            {orderScheduling.isScheduledOrder ? (
              <EventIcon fontSize="medium" color="action" className="mr-2" />
            ) : (
              <DirectionsRunIcon
                fontSize="medium"
                color="action"
                className="mr-2"
              />
            )}
            <span className="ml-1">Trocar</span>
          </Button>
        </div>
      </div>
      <OrderSchedulerModal isOpen={isSchedulerOpen} onClose={closeScheduler} />
    </>
  );
}

function OrderSchedulerConfirmation() {
  const { toGo, orderScheduling } = useSelector((state) => state.checkout);

  const label = toGo ? "Retirada Agendada" : "Entrega Agendada";

  return (
    <div className="d-flex flex-row align-items-center pt-3 pb-5">
      <SchedulerDay
        date={new Date(orderScheduling.schedule.date)}
        borderColor={"border-secondary"}
        textColor={"text-secondary"}
        onClick={(e) => e.preventDefault()}
      />
      <div className="d-flex flex-column ml-3">
        <p className="m-1">{label}</p>
        <p className="m-1 text-capitalize">
          {generateSchedulingText(orderScheduling)}
        </p>
      </div>
    </div>
  );
}

export { OrderSchedulerConfirmation };
