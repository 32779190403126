import React, { useEffect, useState } from "react";
import { Figure } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../../styles/RestingWall.css";

export default function RestingWall() {
  const [current, setCurrent] = useState(0);
  const { pictureUrl, isSelfcheckoutTotem, selfcheckoutConfigs } = useSelector(
    (state) => state.shop
  );

  const selfcheckoutConfig =
    selfcheckoutConfigs?.length > 0 ? selfcheckoutConfigs[0] : {};

  const banners = [
    selfcheckoutConfig.pictureUrlBanner1,
    selfcheckoutConfig.pictureUrlBanner2,
    selfcheckoutConfig.pictureUrlBanner3,
  ].filter((banner) => !!banner);

  useEffect(() => {
    if (banners.length <= 1) return;
    const interval = setInterval(() => {
      setCurrent((c) => (c + 1) % banners.length);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [current, banners.length]);

  if (banners.length === 0) {
    const imageClassName = isSelfcheckoutTotem ? "w-50 my-5" : "w-75";

    return (
      <div className="d-flex flex-column w-100 vh-100 align-items-center bg-dark text-white text-center pt-5">
        <Figure.Image
          src={pictureUrl}
          alt="Logo da loja"
          className={imageClassName}
        />
        <h1 className="mt-5 pt-5 totem-cart-total">FAÇA SEU PEDIDO</h1>
        <h1 className="mt-2 totem-header">TOQUE PARA COMEÇAR</h1>
        <h1 className="mt-2 totem-cart-total">👆</h1>
      </div>
    );
  }

  return (
    <section aria-label="Autoatendimento banners">
      <div class="carousel" data-carousel>
        <ul data-slides>
          {banners.map((banner, index) => (
            <li
              key={banner}
              class={index === current ? "slide slide-active" : "slide"}
            >
              <img src={banner} alt={`Banner ${index}`} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
