import React from "react";
import OrderFollowup from "../containers/OrderFollowup";

export default function OrderStatusScreen() {
  // const { isSelfcheckout } = useSelector((state) => state.shop);

  // if (isSelfcheckout) {
  //   return <SelfCheckoutPayment />;
  // }

  return <OrderFollowup />;
}
