import has from 'lodash.has';

export default function assignPick(mutableObj, iteratee, keysToPick) {
  keysToPick.forEach((key) => {
    if (!has(iteratee, key)) {
      return;
    }

    mutableObj[key] = iteratee[key];
  });
}
