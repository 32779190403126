import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "../../ducks/shoppingCartSlice";
import {
  selectStatefulCosts,
  clearCheckoutTotem,
  submitCheckout,
} from "../../ducks/checkoutSlice";
import useGoTo from "../../hooks/useGoTo";
import useToast from "../../hooks/useToast";
import QuestionCheckout from "../QuestionCheckout";

export default function SelfCheckoutPOS() {
  const dispatch = useDispatch();
  const goTo = useGoTo();
  const { toastError } = useToast();
  const costs = useSelector(selectStatefulCosts);

  const dispatchSelfCheckoutPOS = async () => {
    if (!window.posNativeInterface) {
      toastError("Interface do meio de pagamento nao existe!");
      return;
    }

    try {
      const order = await dispatch(submitCheckout({}));
      window.posNativeInterface.startPosPayment(order.id, costs.total);
      dispatch(clearCart());
      dispatch(clearCheckoutTotem());
      goTo("/");
    } catch (err) {
      console.error(err);
      if (!err.isUserReadable) {
        toastError("Erro desconhecido tentar enviar pedido.", {
          autoClose: 6000,
        });
      }
    }
  };

  return <QuestionCheckout dispatchSubmitCheckout={dispatchSelfCheckoutPOS} />;
}
