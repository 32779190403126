import { createSlice } from "@reduxjs/toolkit";
import reduce from "lodash.reduce";
import Decimal from "decimal.js";
import pickT from "../utils/pickT";
import pick from "lodash.pick";

Decimal.set({ rounding: Decimal.ROUND_HALF_EVEN });

const initialState = Object.freeze({
  cachedTotalCosts: "0.00",
  currentProductId: null,
  productOrders: [],
  answersByOrderCombo: {},
  answersByOrder: {},
  cachedCostPerOrder: {},
  cachedCostPerOrderOld: {},
});

export const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState,
  reducers: {
    refreshCachedCosts: (state, { payload }) => {
      state.productOrders = state.productOrders.filter(
        (o) => o.commited && o.product && o.shopId === payload.shopId
      );
      Object.keys(state.cachedCostPerOrder).forEach((key) => {
        state.cachedCostPerOrderOld[key] = state.cachedCostPerOrder[key];
      });
      state.productOrders.forEach((o) => {
        state.cachedCostPerOrder[o.uuid] = getStatefulOrderCosts(
          o.uuid,
          state,
          payload.products
        ).toString();
      });

      state.cachedTotalCosts = getStatefulTotalCosts(state).toString();
    },
    startOrder: (state, { payload }) => {
      const { uuid, productId, kilogramStep } = pickT(
        payload,
        "uuid",
        "productId",
        "kilogramStep"
      );
      const product = payload.products.items.find((p) => p.id === productId);
      const qtt = product.isKilogram ? kilogramStep : 1;
      state.currentProductId = productId;
      state.productOrders.push({
        uuid,
        productId,
        qtt,
        observations: "",
        commited: false,
        shopId: payload.shopId,
        product,
      });
      state.answersByOrder[uuid] = [];
      state.cachedCostPerOrder[uuid] = getStatefulOrderCosts(
        uuid,
        state,
        payload.products
      ).toString();
    },
    startEditOrder: (state, { payload }) => {
      const { uuid, productId, uuidEdit } = payload;
      state.currentProductId = productId;
      const order = state.productOrders
        .filter((o) => o.uuid === uuidEdit)
        .map((o) => ({ ...o, uuid, commited: false }))[0];
      const questions = payload.products.questionsByProduct[productId] || [];
      const validOptionsIds = questions.reduce(
        (acc, q) => [
          ...acc,
          ...q.options.filter((o) => !o.disabled).map((o) => o.id),
        ],
        []
      );
      const answers = (state.answersByOrder[uuidEdit] || []).filter((a) =>
        validOptionsIds.includes(a.optionId)
      );

      const index = state.productOrders.findIndex((o) => o.uuid === uuidEdit);
      state.productOrders.splice(index, 0, order);
      state.answersByOrder[uuid] = answers;
      state.cachedCostPerOrder[uuid] = getStatefulOrderCosts(
        uuid,
        state,
        payload.products
      ).toString();
    },
    cancelOrder: (state, { payload }) => {
      const { uuid: deletingUuid } = pickT(payload, "uuid");

      state.productOrders = state.productOrders.filter(
        (o) => o.uuid !== deletingUuid
      );

      const filterOrderByUuidKey = (acc, data, uuid) =>
        uuid === deletingUuid ? acc : { ...acc, [uuid]: data };
      state.answersByOrder = reduce(
        state.answersByOrder,
        filterOrderByUuidKey,
        {}
      );
      state.cachedCostPerOrder = reduce(
        state.cachedCostPerOrder,
        filterOrderByUuidKey,
        {}
      );
      state.cachedTotalCosts = getStatefulTotalCosts(state).toString();
    },
    commitOrder: (state, { payload }) => {
      const { uuid } = pickT(payload, "uuid");
      const product = payload.products.items.find(
        (p) => p.id === state.currentProductId
      );
      const answers = state.answersByOrder[uuid];
      const answerOrdained = product.questionsIds.reduce(
        (acc, questionId) => [
          ...acc,
          ...answers.filter((a) => a.questionId === questionId),
        ],
        []
      );
      state.answersByOrder[uuid] = answerOrdained;
      state.productOrders = state.productOrders.map((o) =>
        o.uuid === uuid ? { ...o, commited: true } : o
      );

      state.cachedTotalCosts = getStatefulTotalCosts(state).toString();
    },
    incrementOrderQtt: (state, { payload }) => {
      const { uuid, isKilogram, kilogramStep } = pickT(
        payload,
        "uuid",
        "isKilogram",
        "kilogramStep"
      );
      state.productOrders = state.productOrders.map((o) =>
        o.uuid === uuid
          ? {
              ...o,
              qtt: new Decimal(o.qtt)
                .plus(isKilogram ? kilogramStep : 1)
                .toNumber(),
            }
          : o
      );
      state.cachedCostPerOrder[uuid] = getStatefulOrderCosts(
        uuid,
        state,
        payload.products
      ).toString();
    },
    decrementOrderQtt: (state, { payload }) => {
      const { uuid, isKilogram, kilogramStep } = pickT(
        payload,
        "uuid",
        "isKilogram",
        "kilogramStep"
      );
      state.productOrders = state.productOrders.map((o) =>
        o.uuid === uuid
          ? {
              ...o,
              qtt: new Decimal(o.qtt)
                .minus(isKilogram ? kilogramStep : 1)
                .toNumber(),
            }
          : o
      );
      state.cachedCostPerOrder[uuid] = getStatefulOrderCosts(
        uuid,
        state,
        payload.products
      ).toString();
    },
    answerComboQuestion: (state, { payload }) => {
      const { uuid } = pickT(payload, "uuid");
      const questions =
        payload.products.questionsByProduct[payload.productId] || [];
      const comboQuestion = questions.find((q) =>
        q.options.some((o) => o.comboItem)
      );
      if (comboQuestion) {
        state.answersByOrder[uuid] = comboQuestion.options.map((o) => ({
          orderUuid: uuid,
          questionId: comboQuestion.id,
          optionId: o.id,
          question: comboQuestion,
          qtt: 1,
        }));

        state.cachedCostPerOrder[uuid] = getStatefulOrderCosts(
          uuid,
          state,
          payload.products
        ).toString();
      }

      state.answersByOrderCombo[uuid] = true;
    },
    answerSingleChoiceQuestion: (state, { payload }) => {
      const answer = pickT(
        payload,
        "orderUuid",
        "questionId",
        "optionId",
        "question"
      );
      const isChecked = payload.isChecked;

      state.answersByOrder[answer.orderUuid] = (
        state.answersByOrder[answer.orderUuid] || []
      ).filter((a) => a.questionId !== answer.questionId);
      if (isChecked) {
        state.answersByOrder[answer.orderUuid].push({ ...answer, qtt: 1 });
      }
      state.cachedCostPerOrder[answer.orderUuid] = getStatefulOrderCosts(
        answer.orderUuid,
        state,
        payload.products
      ).toString();
    },
    answerManyChoicesQuestion: (state, { payload }) => {
      const answer = pickT(
        payload,
        "orderUuid",
        "questionId",
        "optionId",
        "qtt",
        "question"
      );
      const existing = state.answersByOrder[answer.orderUuid].find(
        (a) => a.optionId === answer.optionId
      );
      if (existing) {
        if (answer.qtt > 0) {
          state.answersByOrder[answer.orderUuid] = state.answersByOrder[
            answer.orderUuid
          ].reduce(
            (acc, a) =>
              a.optionId === answer.optionId ? [...acc, answer] : [...acc, a],
            []
          );
        } else {
          state.answersByOrder[answer.orderUuid] = state.answersByOrder[
            answer.orderUuid
          ].filter((a) => a.optionId !== answer.optionId);
        }
      } else {
        state.answersByOrder[answer.orderUuid].push(answer);
      }
      state.cachedCostPerOrder[answer.orderUuid] = getStatefulOrderCosts(
        answer.orderUuid,
        state,
        payload.products
      ).toString();
    },
    setObservations: (state, { payload }) => {
      const productOrder = state.productOrders.find(
        (o) => o.uuid === payload.uuid
      );
      productOrder.observations = payload.observations;
    },
    clearCart: () => {
      return initialState;
    },
  },
});

const rawActionCreators = pick(
  shoppingCartSlice.actions,
  "refreshCachedCosts",
  "startOrder",
  "startEditOrder",
  "cancelOrder",
  "commitOrder",
  "answerComboQuestion",
  "answerSingleChoiceQuestion",
  "answerManyChoicesQuestion",
  "incrementOrderQtt",
  "decrementOrderQtt",
  "setObservations",
  "clearCart"
);
const thunksAwareOfPrices = reduce(
  rawActionCreators,
  (acc, createAction, key) => {
    acc[key] = (payload) => (dispatch, getState) => {
      const { products, shop } = getState();
      dispatch(createAction({ ...payload, products, shopId: shop.id }));
    };
    return acc;
  },
  {}
);
export const {
  refreshCachedCosts,
  startOrder,
  startEditOrder,
  cancelOrder,
  commitOrder,
  answerComboQuestion,
  answerSingleChoiceQuestion,
  answerManyChoicesQuestion,
  incrementOrderQtt,
  decrementOrderQtt,
  setObservations,
  clearCart,
} = thunksAwareOfPrices;

export const flushPendingOrders = () => (dispatch, getState) => {
  getState().shoppingCart.productOrders.forEach((order) => {
    if (!order.commited) {
      dispatch(cancelOrder({ uuid: order.uuid }));
    }
  });
};

export default shoppingCartSlice.reducer;

const getStatefulOrderCosts = (orderUuid, shoppingCart, products) => {
  const order = shoppingCart.productOrders.find((o) => o.uuid === orderUuid);
  const answers = shoppingCart.answersByOrder[order.uuid];

  const product = products.items.find((p) => p.id === order.productId);
  const questions = products.questionsByProduct[order.productId] || [];
  const productBasicPrice = Decimal(product ? product.basicPrice : 0);

  const questionMAX = questions.find(
    (q) => q.pizzaPriceMode === PRICE_MODE_MAX
  );
  const answersMAX = questionMAX
    ? answers.filter((a) => a.questionId === questionMAX.id)
    : [];
  const answersSUM = questionMAX
    ? answers.filter((a) => a.questionId !== questionMAX.id)
    : answers;

  const priceByOption = questions.reduce(
    (prices, question) => ({
      ...prices,
      ...question.options.reduce(
        (os, option) => ({
          ...os,
          [option.id]: Decimal(option.price),
        }),
        {}
      ),
    }),
    {}
  );

  const basicPricePizza =
    answersMAX.length === 0
      ? 0
      : Math.max(...answersMAX.map((a) => priceByOption[a.optionId]));

  const totalOrderAnswers = answersSUM.reduce((total, answer) => {
    const optionPrice = priceByOption[answer.optionId] || Decimal(0);
    return total.plus(optionPrice.times(answer.qtt).toDecimalPlaces(2));
  }, Decimal(0));

  return productBasicPrice
    .plus(basicPricePizza)
    .plus(totalOrderAnswers)
    .times(order.qtt)
    .toDecimalPlaces(2);
};

const getStatefulTotalCosts = (shoppingCart) => {
  const commitedCosts = shoppingCart.productOrders.reduce(
    (costs, order) =>
      order.commited
        ? costs.plus(Decimal(shoppingCart.cachedCostPerOrder[order.uuid]))
        : costs,
    Decimal("0.00")
  );
  return commitedCosts;
};

export const PRICE_MODE_MAX = "MAXIMO";
