import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import TextField from "@material-ui/core/TextField";
import {
  setFormAnswer,
  setCouponRemove,
  searchCouponValidation,
} from "../ducks/checkoutSlice";
import { selectStatefulCosts } from "../ducks/checkoutSlice";

const styles = {
  body: {
    margin: 10,
  },
  cash: {
    margin: 50,
  },
};

export default function CouponSelection({ className }) {
  const dispatch = useDispatch();
  const [isCouponFormVisible, setCouponFormVisible] = React.useState(false);
  const [typingCoupon, setTypingCoupon] = React.useState("");
  const { coupon, isSearchingCoupon, isValidCoupon, couponError } = useSelector(
    (state) => state.checkout
  );

  const costs = useSelector(selectStatefulCosts);
  const error = couponError || costs.couponError;

  const onCouponChange = (event) => setTypingCoupon(event.target.value);

  const onToChangeClick = () => {
    setCouponFormVisible(true);
  };

  const removeCoupon = () => {
    dispatch(setCouponRemove());
    setCouponFormVisible(false);
  };

  const cancelCouponForm = () => setCouponFormVisible(false);

  const onSubmit = (event) => {
    event.preventDefault();

    dispatch(setFormAnswer({ name: "coupon", value: typingCoupon.trim() }));
    dispatch(searchCouponValidation());

    setCouponFormVisible(false);
  };

  return (
    <div className="p-3">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-column">
          <p className="font-weight-bold m-0">Cupom</p>
          {coupon && (
            <p className={`mt-1 mb-0${isValidCoupon ? " text-success" : ""}`}>
              <small>{coupon}</small>
            </p>
          )}
          {error && (
            <p className="text-shop mt-1 mb-0">
              <small>{error}</small>
            </p>
          )}
        </div>
        <Button
          variant="link"
          onClick={onToChangeClick}
          disabled={isSearchingCoupon}
        >
          {coupon ? "Trocar" : "Inserir"}
        </Button>
      </div>
      <Modal show={isCouponFormVisible} onHide={cancelCouponForm}>
        <Modal.Header closeButton>
          <Modal.Title as="h3">Cupom</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onSubmit}>
          <Modal.Body style={styles.body}>
            <TextField
              fullWidth={true}
              label="Código do cupom"
              error={error ? true : false}
              helperText={error}
              variant="outlined"
              onChange={onCouponChange}
              value={typingCoupon}
              disabled={isSearchingCoupon}
              required
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-shop" onClick={removeCoupon}>
              Remover
            </Button>
            <Button variant="shop" type="submit" disabled={isSearchingCoupon}>
              Validar cupom
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
