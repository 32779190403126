import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { ProductDivider } from "../components/ProductsListCart";
import Divider from "../components/Divider";
import toPriceDisplay from "../utils/toPriceDisplay";
import { domScrollToBottom } from "../utils/domScrollTo";
import WhatsAppButton from "../components/WhatsAppButton";
import phoneDisplay from "../utils/phoneDisplay";
import documentDisplay from "../utils/documentDisplay";
import documentLabelDisplay from "../utils/documentLabelDisplay";

export default function OrderDetailsStatus({
  shortReference,
  shop,
  cache,
  order,
  isOrderVisibleInitialValue,
  className,
}) {
  const [isOrderVisible, setOrderVisible] = React.useState(
    isOrderVisibleInitialValue
  );
  const [isShopVisible, setShopVisible] = React.useState(false);

  const handleClickDetails = () => setOrderVisible(!isOrderVisible);
  const handleClickShop = () => {
    setShopVisible(!isShopVisible);
    setTimeout(domScrollToBottom, 250);
  };

  const checkout = cache ? cache.checkout : null;
  const deliveryAddress = cache ? checkout.deliveryAddress : null;
  const costs = cache ? cache.costs : null;
  const mode = order.toGo
    ? "para retirar"
    : order.toDelivery
    ? "para entrega"
    : `na loja`;
  const inLocoTableText = Number(order.tableId)
    ? `${shop.inLocoTitle} ${order.tableId}`
    : order.tableId;
  const inLocoText =
    checkout && checkout.inLocoLocation
      ? `${checkout.inLocoLocation} ${inLocoTableText}`
      : inLocoTableText;
  const message = `Referente ao pedido *${mode}* nº *${shortReference}*`;

  return (
    <Container className={`p-0 m-0 ${className}`}>
      {cache && (
        <Container className="pb-3 pt-3">
          <Button className="p-0" variant="link" onClick={handleClickDetails}>
            Detalhes do pedido
          </Button>
        </Container>
      )}
      {cache && isOrderVisible && (
        <>
          <Container>
            <p className="m-0 pb-3 font-weight-bold">Produtos</p>
            {cache.orderedProducts.map((product, productIndex) => (
              <div
                className="pb-3"
                key={`cached-product-${cache.orderId}-${productIndex}`}
              >
                <div className="pb-3 d-flex flex-row justify-content-between align-items-center">
                  <div>
                    <p className="m-0">{product.name}</p>
                    {product.detailsList && product.detailsList.length > 0 && (
                      <ul className="mb-0">
                        {product.detailsList.map((detail, index) => (
                          <li key={index}>
                            <small className="text-muted">{detail}</small>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <p className="m-0">{toPriceDisplay(product.price)}</p>
                  </div>
                </div>
                <ProductDivider />
              </div>
            ))}

            <div className="pr-3 pl-3">
              {costs.showSubtotal && (
                <p className="d-flex justify-content-between text-muted">
                  <span>Subtotal</span>
                  <span>{toPriceDisplay(costs.subtotal)}</span>
                </p>
              )}

              {checkout.toDelivery && (
                <p className="d-flex justify-content-between text-muted">
                  <span>Entrega</span>
                  <span className="text-muted">
                    <MotorcycleIcon fontSize="small" className="mr-2" />
                    {toPriceDisplay(costs.delivery)}
                  </span>
                </p>
              )}

              {checkout.coupon && (
                <p className="d-flex justify-content-between text-muted">
                  <span>Cupom</span>
                  <span className="text-success">
                    <LocalOfferIcon fontSize="small" className="mr-2" />-
                    {toPriceDisplay(costs.discount)}
                  </span>
                </p>
              )}

              {costs.hasTakeoutDiscount && (
                <p className="d-flex justify-content-between text-muted">
                  <span>Desconto para retirar</span>
                  <span className="text-success">
                    <LocalOfferIcon fontSize="small" className="mr-2" />-
                    {toPriceDisplay(costs.takeoutDiscount)}
                  </span>
                </p>
              )}

              {costs.fidelityDiscount && (
                <p className="d-flex justify-content-between text-muted">
                  <span>Desconto fidelidade</span>
                  <span className="text-success">
                    <LocalOfferIcon fontSize="small" className="mr-2" />-
                    {toPriceDisplay(costs.fidelityDiscount)}
                  </span>
                </p>
              )}

              <p className="d-flex justify-content-between font-weight-bold">
                <span>Total</span>
                <span>{toPriceDisplay(costs.total)}</span>
              </p>

              {checkout.payment && (
                <p className="d-flex justify-content-between text-muted">
                  <span>{checkout.payment}</span>
                  <span>{toPriceDisplay(costs.paymentValue)}</span>
                </p>
              )}

              {costs.paymentIsCash && (
                <p className="d-flex justify-content-between text-muted">
                  <span>Troco</span>
                  <span>{toPriceDisplay(costs.paymentDiff)}</span>
                </p>
              )}
            </div>
          </Container>
          <Divider className="mb-3" />
          <Container className="pb-3">
            <p className="m-0 font-weight-bold">Cliente</p>
            <p className="mt-1 m-0 text-muted">
              <small>
                {`Nome: ${checkout.name}`}
                <br />
                {`Telefone: ${phoneDisplay(checkout.telephone)}`}
                <br />
                {`${documentLabelDisplay(
                  checkout.identification
                )}: ${documentDisplay(checkout.identification)}`}
              </small>
            </p>

            {cache.isInLoco && (
              <div>
                <p className="mt-3 mb-0">O pedido será entregue em:</p>
                <p className="mb-0 font-weight-bold">{inLocoText}</p>
              </div>
            )}

            {checkout.toGo && (
              <p className="mt-3 mb-0 font-weight-bold">Retirar no balcão</p>
            )}

            {checkout.toDelivery && (
              <div>
                <p className="mt-3 mb-0 font-weight-bold">
                  {deliveryAddress.addressStreet},{" "}
                  {deliveryAddress.addressNumber}
                  {deliveryAddress.complement
                    ? ", " + deliveryAddress.complement
                    : ""}
                </p>
                <p className="m-0 text-muted">
                  <small>
                    {deliveryAddress.neighborhood}, {deliveryAddress.city}/
                    {deliveryAddress.state} - CEP {deliveryAddress.postalCode}
                    <br />
                  </small>
                </p>
              </div>
            )}
          </Container>
        </>
      )}
      {shop && shop.id === order.shopId && (
        <Container className="pt-3 pb-5">
          <p className="mb-0">Se deseja modificar seu pedido,</p>
          <Button className="p-0" variant="link" onClick={handleClickShop}>
            entre em contato com a loja.
          </Button>
          {isShopVisible && (
            <>
              <p className="mt-1 mb-0 text-muted">
                {`Telefone: ${phoneDisplay(shop.phone)}`}
              </p>
              <WhatsAppButton whatsapp={shop.whatsapp} message={message} />
            </>
          )}
        </Container>
      )}
    </Container>
  );
}
