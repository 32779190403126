import React from "react";
import NumberFormat from "react-number-format";

function NumberInput(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

function AddressNumberInput(props) {
  return <NumberInput {...props} thousandSeparator={false} />;
}

function PhoneInput(props) {
  const format =
    props.value.length === 11 ? "(##) #####-####" : "(##) ####-#####";
  return <NumberInput {...props} format={format} />;
}

function CPFCNPJInput(props) {
  const format =
    props.value.length <= 11 ? "###.###.###-###" : "##.###.###/####-##";
  return <NumberInput {...props} format={format} />;
}

function CEPInput(props) {
  return <NumberInput {...props} format="#####-###" />;
}

function MoneyInput(props) {
  return (
    <NumberInput {...props} allowNegative={false} format={currencyFormatter} />
  );
}

function currencyFormatter(value) {
  if (!Number(value)) return "";

  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value / 100);

  return `${amount}`;
}

function CreditCardNumberInput(props) {
  return <NumberInput {...props} format="#### #### #### ####" />;
}

function CreditCardValidInput(props) {
  return <NumberInput {...props} format="## / ##" />;
}

function CreditCardCVVInput(props) {
  return <NumberInput format="####" {...props} />;
}

function BirthdayInput(props) {
  return <NumberFormat format="##-##-####" {...props} />;
}

export default NumberInput;

export {
  AddressNumberInput,
  PhoneInput,
  CPFCNPJInput,
  CEPInput,
  MoneyInput,
  BirthdayInput,
  CreditCardNumberInput,
  CreditCardValidInput,
  CreditCardCVVInput,
};
