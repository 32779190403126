import React from "react";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash.get";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Figure from "react-bootstrap/Figure";
import DoneIcon from "@material-ui/icons/Done";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import toPriceDisplay from "../utils/toPriceDisplay";
import addLineBreaks from "../utils/addLineBreaks";
import {
  answerSingleChoiceQuestion,
  answerManyChoicesQuestion,
} from "../ducks/shoppingCartSlice";
import FormCheck from "react-bootstrap/FormCheck";
import ObservationsCard from "./ObservationsCard";

export default function QuestionsCards({
  isAvailableToOrder,
  productId,
  orderUuid,
  className,
}) {
  const questions = useSelector(
    (state) => state.products.questionsByProduct[productId]
  );

  return (
    <Container className={className}>
      {questions.map((question) => (
        <QuestionCard
          key={question.id}
          isAvailableToOrder={isAvailableToOrder}
          question={question}
          orderUuid={orderUuid}
          className="mt-4"
        />
      ))}
      <ObservationsCard
        orderUuid={orderUuid}
        isAvailableToOrder={isAvailableToOrder}
      />
    </Container>
  );
}

export const QuestionCard = ({
  isAvailableToOrder,
  question,
  orderUuid,
  className,
}) => {
  const { description, minQtt, maxQtt } = question;
  const answers =
    useSelector((state) => state.shoppingCart.answersByOrder[orderUuid]) || [];
  const questionAnswers = answers.filter((a) => a.questionId === question.id);
  const qtt = questionAnswers.reduce((acc, a) => acc + a.qtt, 0);

  const isSingleChoice = maxQtt === 1;
  const isCombo = question.options.reduce((acc, o) => acc && o.comboItem, true);

  const getHelpText = () => {
    if (isCombo) {
      return "";
    } else if (minQtt === maxQtt) {
      return `Escolha ${minQtt}`;
    } else if (minQtt > 0) {
      return `Escolha ${minQtt}, até ${maxQtt}`;
    } else {
      return `Escolha até ${maxQtt}`;
    }
  };

  return (
    <Card className={className}>
      <QuestionTitle
        description={description}
        helpText={getHelpText()}
        minQtt={minQtt}
        qtt={qtt}
      />
      {question.options.length > 0 && (
        <OptionsList
          isAvailableToOrder={isAvailableToOrder}
          question={question}
          orderUuid={orderUuid}
          maxQtt={maxQtt}
          isSingleChoice={isSingleChoice}
          answers={questionAnswers}
        />
      )}
    </Card>
  );
};

const QuestionTitle = ({ description, helpText, minQtt, qtt }) => {
  return (
    <Card.Title
      as="h3"
      className="d-flex flex-row justify-content-between align-items-end bg-light m-0 p-3"
    >
      <span>
        {description}
        <br />
        <small className="text-muted">{helpText}</small>
      </span>
      {minQtt > 0 && qtt < minQtt && (
        <div>
          {minQtt > 1 && (
            <Badge variant="danger" className="mr-1">
              <small>{`${qtt}/${minQtt}`}</small>
            </Badge>
          )}
          <Badge variant="danger">
            <small>OBRIGATÓRIO</small>
          </Badge>
        </div>
      )}
      {minQtt > 0 && qtt >= minQtt && (
        <Badge variant="success">
          <DoneIcon fontSize="inherit" />
        </Badge>
      )}
    </Card.Title>
  );
};

const OptionsList = (props) => {
  const {
    isAvailableToOrder,
    question,
    orderUuid,
    maxQtt,
    isSingleChoice,
    answers,
  } = props;

  const { id: questionId, isAllowedOptionRepetition } = question;

  const dispatch = useDispatch();

  const statefulOptions = question.options.map((option) => {
    const answer = answers.find((a) => a.optionId === option.id && a.qtt > 0);
    const answeredQtt = get(answer, "qtt", 0);
    const isChecked = answeredQtt === 1;
    return {
      ...option,
      answeredQtt,
      isChecked,
      onRadioClick: () =>
        dispatch(
          answerSingleChoiceQuestion({
            questionId,
            orderUuid,
            optionId: option.id,
            isChecked: !isChecked,
            question,
          })
        ),
      onCheckboxClick: () =>
        dispatch(
          answerManyChoicesQuestion({
            questionId,
            orderUuid,
            optionId: option.id,
            qtt: isChecked ? 0 : 1,
            question,
          })
        ),
      onIncrementClick: () =>
        dispatch(
          answerManyChoicesQuestion({
            questionId,
            orderUuid,
            optionId: option.id,
            qtt: answeredQtt + 1,
            question,
          })
        ),
      onDecrementClick: () =>
        dispatch(
          answerManyChoicesQuestion({
            questionId,
            orderUuid,
            optionId: option.id,
            qtt: answeredQtt - 1,
            question,
          })
        ),
    };
  });

  const questionTotalAnsweredQtt = answers.reduce((acc, a) => acc + a.qtt, 0);
  const isIncrementDisabled = questionTotalAnsweredQtt >= maxQtt;

  return (
    <ListGroup className="m-0 p-0">
      {statefulOptions.map((option) =>
        option.comboItem ? (
          <OptionComboItem questionId={questionId} option={option} />
        ) : (
          <Option
            questionId={questionId}
            isAllowedOptionRepetition={isAllowedOptionRepetition}
            isAvailableToOrder={isAvailableToOrder}
            isSingleChoice={isSingleChoice}
            isIncrementDisabled={isIncrementDisabled}
            option={option}
          />
        )
      )}
    </ListGroup>
  );
};

const Option = ({
  questionId,
  isAllowedOptionRepetition,
  isAvailableToOrder,
  isSingleChoice,
  isIncrementDisabled,
  option,
}) => {
  return (
    <ListGroup.Item key={option.id} className="m-0 p-0">
      <FormCheck
        id={`question-${questionId}-option-${option.id}`}
        className="d-flex flex-row-reverse align-items-center"
      >
        {isAvailableToOrder &&
          !option.disabled &&
          isAllowedOptionRepetition && (
            <div className="d-flex align-items-center">
              <Button
                variant="link"
                onClick={option.onDecrementClick}
                style={{
                  visibility: option.answeredQtt > 0 ? "visible" : "hidden",
                }}
              >
                <RemoveIcon />
              </Button>
              {option.answeredQtt > 0 && <span>{option.answeredQtt}</span>}
              <Button
                variant="link"
                onClick={option.onIncrementClick}
                disabled={isIncrementDisabled}
              >
                <AddIcon />
              </Button>
            </div>
          )}
        {isAvailableToOrder &&
          !option.disabled &&
          !isAllowedOptionRepetition &&
          isSingleChoice && (
            <Form.Check
              type="radio"
              className="p-3 cursor-pointer"
              checked={option.isChecked}
              onClick={option.onRadioClick}
              onChange={() => {}}
            />
          )}
        {isAvailableToOrder &&
          !option.disabled &&
          !isAllowedOptionRepetition &&
          !isSingleChoice &&
          (!isIncrementDisabled || option.isChecked) && (
            <Form.Check
              type="checkbox"
              className="p-3 cursor-pointer"
              checked={option.isChecked}
              onClick={option.onCheckboxClick}
              onChange={() => {}}
            />
          )}
        <Form.Label className="w-100 p-0 m-0">
          <Row>
            {option.pictureUrl && (
              <Col
                xs="4"
                className="d-flex flex-column justify-content-center p-0"
              >
                <Figure.Image
                  src={option.pictureUrl}
                  alt="Ilustração da opção"
                  fluid={false}
                  className="w-100 my-4"
                  rounded
                />
              </Col>
            )}
            <Col xs={option.pictureUrl ? 8 : 12} className="py-3">
              <span>{option.label}</span>
              {option.details && (
                <p className="mb-0 text-muted">
                  <small>{addLineBreaks(option.details)}</small>
                </p>
              )}
              {option.disabled && (
                <>
                  <br />
                  <Badge variant="secondary">
                    <small>INDISPONÍVEL</small>
                  </Badge>
                </>
              )}
              {!option.disabled && !!parseFloat(option.price) && (
                <p className="m-0 mt-2">
                  <span>+ {toPriceDisplay(option.price)}</span>
                </p>
              )}
            </Col>
          </Row>
        </Form.Label>
      </FormCheck>
    </ListGroup.Item>
  );
};

const OptionComboItem = ({ questionId, option }) => {
  return (
    <ListGroup.Item key={option.id} className="m-0 p-0">
      <FormCheck
        id={`question-${questionId}-option-${option.id}`}
        className="d-flex flex-row-reverse align-items-center"
      >
        <Form.Label className="w-100 p-0 m-0">
          <Row>
            {option.pictureUrl && (
              <Col
                xs="4"
                className="d-flex flex-column justify-content-center p-0"
              >
                <Figure.Image
                  src={option.pictureUrl}
                  alt="Ilustração da opção"
                  fluid={false}
                  className="w-100 my-4"
                  rounded
                />
              </Col>
            )}
            <Col xs={option.pictureUrl ? 8 : 12} className="pt-3 pb-3">
              <span>{option.label}</span>
              {option.details && (
                <p className="mb-0 text-muted">
                  <small>{addLineBreaks(option.details)}</small>
                </p>
              )}
            </Col>
          </Row>
        </Form.Label>
      </FormCheck>
    </ListGroup.Item>
  );
};
