import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { Typography } from "@material-ui/core";
import { STATUS_DETAILS } from "../../ducks/consts";
import { Container, ListGroup } from "react-bootstrap";
import posAutoatendimentoNumeroPedido from "../../assets/payment-flat-rounded/pos-autoatendimento-numero-pedido.jpg";
import Loading from "../../components/Loading";
import OrderDetailsStatus from "../OrderDetailsStatus";

const useStyles = makeStyles(() => ({
  divEstorno: {
    background: `red`,
    padding: `1rem`,
    color: `white`,
    borderRadius: `1rem`,
  },
  divPagamentoSucesso: {
    background: `green`,
    padding: `1rem`,
    color: `white`,
    borderRadius: `1rem`,
  },
  divPagamentoPendente: {
    background: `#ffc107`,
    padding: `1rem`,
    color: `black`,
    borderRadius: `1rem`,
  },
  icon: {
    width: 80,
    height: 80,
    marginBottom: `1rem`,
  },
}));

export default function SelfCheckoutQRCodeStatus({
  shortReference,
  shop,
  cache,
  order,
  status,
}) {
  const classes = useStyles();

  if (!order || !status) {
    return (
      <Container>
        <Loading message="Carregando Status Autoatendimento QRCode..." />
      </Container>
    );
  }

  const cancelled = status.find(
    (s) => s.code === STATUS_DETAILS.CANCELLED.code
  );

  const paid = status.find((s) => s.code === STATUS_DETAILS.PAYMENT.code);

  return (
    <div>
      <div className="px-3 text-center">
        <div className="d-flex flex-row justify-content-center my-4">
          <Typography variant="h4">
            <strong>{`Seu pedido #${shortReference} foi realizado com sucesso!`}</strong>
          </Typography>
        </div>
        {cancelled && paid && (
          <div
            className={`d-flex flex-column align-items-center ${classes.divEstorno}`}
          >
            <CancelIcon className={classes.icon} />
            <Typography variant="h6">
              <strong>
                Ops, a loja teve um problema e cancelou seu pedido, dirija-se
                até o caixa para realizar o estorno do pagamento.
              </strong>
            </Typography>
          </div>
        )}
        {!cancelled && paid && (
          <div className={`d-flex flex-column`}>
            <div
              className={`d-flex flex-column align-items-center ${classes.divPagamentoSucesso}`}
            >
              <MonetizationOnIcon className={classes.icon} />
              <Typography variant="h5" className="my-2">
                Pagamento concluido!
              </Typography>
            </div>
            <div className="d-flex flex-row">
              <Typography variant="h5">
                <strong>
                  <br />
                  <br />
                  Vamos iniciar o preparo do seu pedido e quanto estiver pronto
                  vamos chamar pelo número #{shortReference}
                </strong>
              </Typography>
            </div>
          </div>
        )}
        {!cancelled && !paid && (
          <div className={`d-flex flex-column`}>
            <div
              className={`d-flex flex-column mb-4 align-items-center ${classes.divPagamentoPendente}`}
            >
              <Typography variant="h6">
                <strong>Pagamento pendente</strong>
                <br />
                <small>siga os passos abaixo</small>
              </Typography>
            </div>
            <div className="d-flex flex-row">
              <img
                src={posAutoatendimentoNumeroPedido}
                height="330rem"
                alt="maquininha de cartão"
              />
              <ListGroup variant="flush" className="text-left ml-4">
                <ListGroup.Item className="px-0 py-4">
                  <Typography variant="h6">
                    <strong>1. </strong>Dirija-se até uma das maquininhas
                  </Typography>
                </ListGroup.Item>
                <ListGroup.Item className="px-0 py-4">
                  <Typography variant="h6">
                    <strong>2. </strong>Digite o número do pedido{" "}
                    <strong>{shortReference}</strong>
                  </Typography>
                </ListGroup.Item>
                <ListGroup.Item className="px-0 py-4">
                  <Typography variant="h6">
                    <strong>3. </strong>Realize o pagamento
                  </Typography>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </div>
        )}
      </div>
      <OrderDetailsStatus
        className={"mt-5"}
        shortReference={shortReference}
        cache={cache}
        shop={shop}
        order={order}
        isOrderVisibleInitialValue={false}
      />
    </div>
  );
}
