import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import groupBy from 'lodash.groupby';
import Container from 'react-bootstrap/Container';
import domScrollTo from '../utils/domScrollTo';
import ProductsList from '../components/ProductsList';
import CommonHeader from '../containers/CommonHeader';
import FloatingCartSummary from '../containers/FloatingCartSummary';
import useGoTo from '../hooks/useGoTo';
import { getProductAvailability } from '../hooks/useWorkingTime';

export default function GroupsListView() {
  const goTo = useGoTo();
  const routeMatch = useRouteMatch();
  const search = useSelector(state => state.search);
  const { items: products } = useSelector(state => state.products);
  const groupId = parseInt(routeMatch.params.groupId, 10);
  const group = useSelector(state => state.products.groups.find(g => g.id === groupId));
  
  const { isAvailableToOrder } = useSelector(state => state.shop);
  const orders = useSelector(state => state.shoppingCart.productOrders);
  const commitedOrders = orders.filter(o => o.commited);
  const isCartVisible = isAvailableToOrder && commitedOrders.length > 0;

  React.useEffect(() => {
    domScrollTo(0, 'auto');
  }, []);

  if (!group) {
    return (
      <>
        <CommonHeader text="Grupo"/>
        <Container className="text-center mt-3 mb-3">
          <h1>Ops...</h1>
          <p>Este grupo não existe mais.</p>
        </Container>
      </>
    );
  }

  const availableProducts = products.filter(p => getProductAvailability(p).isAvailable);

  const filteredProducts = availableProducts.filter(p =>
    (p.name || '').toLowerCase().includes(search.content.toLowerCase())
    || (p.details || '').toLowerCase().includes(search.content.toLowerCase())
  );
  const filteredProductsByGroup = groupBy(filteredProducts, 'groupId');

  const onProductSelect = productId => goTo(`/product/${productId}`);

  return (
    <>
      <div className={`shop-home-screen--${isCartVisible ? 'stretched' : 'normal'}`}>
        <CommonHeader text={group.name} />
        <ProductsList
          products={filteredProductsByGroup[group.id]}
          onProductSelect={onProductSelect}
        />
      </div>
      {isCartVisible && <FloatingCartSummary />}
    </>
  );
}