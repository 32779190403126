import React from "react";
import { Form, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import CommonHeader from "../containers/CommonHeader";
import { confirmLogin, loginUser } from "../services/fidelity";
import useToast from "../hooks/useToast";
import { useDispatch, useSelector } from "react-redux";
import useGoTo from "../hooks/useGoTo";
import { setSendCodeTimeStamp, setConfirmation } from "../ducks/userSlice";
import { setFormAnswer } from "../ducks/checkoutSlice";
import phoneDisplay from "../utils/phoneDisplay";
import SendCodeWarning from "../components/SendCodeWarning";
import timeout from "../utils/timeout";

export default function ConfirmationScreen() {
  const dispatch = useDispatch();
  const goTo = useGoTo();
  const toast = useToast();
  const { userPhone, userId, sendCodeChannel } = useSelector(
    (state) => state.user
  );
  const { name, telephone } = useSelector((state) => state.checkout);
  const [code, setCode] = React.useState("");
  const [error, setError] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);
  const [showCodeWaring, setShowCodeWarning] = React.useState(true);

  const onChangeCode = (event) => {
    setCode(event.target.value);
  };

  const finishLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await confirmLogin(userId, code).then((resp) =>
        resp.json()
      );

      if (response.error) {
        if (response.error.statusCode === 400) {
          setError("Código inválido. Tente novamente!");
          return;
        }

        throw new Error(response.error?.message ?? "Something wrong happened");
      }

      fillCheckoutForm(response);
      dispatch(setConfirmation(response));
      toast.toastSuccess("Login realizado com sucesso!");
      goTo("/");
    } catch (error) {
      toast.toastError("Erro ao realizar o login!");
    } finally {
      setLoading(false);
    }
  };

  const fillCheckoutForm = (response) => {
    if (!name)
      dispatch(
        setFormAnswer({
          name: "name",
          value: response.nome,
        })
      );

    if (!telephone)
      dispatch(
        setFormAnswer({
          name: "telephone",
          value: response.telefone,
        })
      );
  };

  const resendCode = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await loginUser(userPhone).then((resp) => resp.json());

      await dispatch(
        setSendCodeTimeStamp({ sendCodeChannel: response.channel })
      );

      if (response.error) {
        if (response.error.statusCode === 422) {
          goTo("/register");
          return;
        }

        throw new Error(response.error?.message ?? "Something wrong happened");
      }
    } catch (error) {
      toast.toastError(
        "Erro ao realizar no envio do código. Por favor, tente novamente."
      );
    } finally {
      await timeout(1000);
      setLoading(false);
    }
  };

  const isButtonDisabled = code.trim().length === 0 || isLoading;

  const backToLogin = () => {
    goTo("/login");
    return false;
  };

  return (
    <>
      <CommonHeader text="Confirmação" onBack={backToLogin} />
      <div className="mt-3 text-center">
        Insira o código que foi enviado para
      </div>
      <div className="mt-2 text-center">
        <strong>{`${phoneDisplay(userPhone)} via ${sendCodeChannel}`}</strong>
      </div>
      <Form className="mt-4 px-3 px-lg-0" onSubmit={finishLogin}>
        <TextField
          className="mt-3"
          autoFocus
          fullWidth={true}
          variant="outlined"
          label=""
          value={code}
          onChange={onChangeCode}
          error={!!error}
          helperText={error}
          required
          disabled={isLoading}
        />
        <SendCodeWarning
          showWarning={showCodeWaring}
          setShowWarning={setShowCodeWarning}
          resend={
            <ButtonResendCode onClick={resendCode} isLoading={isLoading} />
          }
        />
        <div className="p-3 fixed-bottom">
          <Button
            type="submit"
            className="w-100"
            variant="danger"
            disabled={isButtonDisabled}
          >
            Concluir Login
          </Button>
        </div>
      </Form>
    </>
  );
}

function ButtonResendCode({ isLoading, ...rest }) {
  return (
    <Button
      className="w-100 mt-5"
      variant="link"
      disabled={isLoading}
      {...rest}
    >
      Reenviar código
    </Button>
  );
}
