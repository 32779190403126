import { useRef } from 'react';
import { toast } from 'react-toastify';

export default function useToast() {
  return useRef(exposedToast).current;
}

const params = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 2000,
};

const exposedToast = {
  toastSuccess: (text, extra = {}) => toast.success(text, { ...params, ...extra }),
  toastError: (text, extra = {}) => toast.error(text, { ...params, ...extra }),
}
