import React from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { setFilterByContent } from "../ducks/searchSlice";

export default function BasicSearch() {
  const dispatch = useDispatch();
  const debouncedDispatch = React.useRef(debounce(dispatch, 200));
  const searchingContent = useSelector((state) => state.search.content);

  const handleContentFilterChange = (event) => {
    const content = event.target.value;
    debouncedDispatch.current(setFilterByContent({ content }));
  };

  return (
    <Container className="mt-4 mb-4">
      <Form onSubmit={(e) => e.preventDefault()}>
        <TextField
          type="search"
          label="Filtro por nome ou descrição"
          onChange={handleContentFilterChange}
          defaultValue={searchingContent}
          variant="outlined"
          fullWidth={true}
        />
      </Form>
    </Container>
  );
}
