import StarRoundedIcon from "@material-ui/icons/StarRounded";
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { v4 as uuidv4 } from "uuid";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import toPriceDisplay from "../utils/toPriceDisplay";
import Decimal from "decimal.js";
import React from "react";
import { getValidOrders } from "../services/fidelity";
import { setValidOrders } from "../ducks/userSlice";
import useToast from "../hooks/useToast";
import useGoTo from "../hooks/useGoTo";
import formatDateTime from "../utils/formatDateTime";

export default function FidelityButton({ className, divider }) {
  const dispatch = useDispatch();
  const goTo = useGoTo();
  const redirectToFidelityPage = () => goTo("/fidelity");

  const {
    shop: { fidelity },
  } = useSelector((state) => state);

  // Clear validOrders state when a store disabled the fidelity program
  React.useEffect(() => {
    if (!fidelity) {
      dispatch(setValidOrders({ validOrders: [] }));
    }
  }, [dispatch, fidelity]);

  return fidelity ? (
    <div>
      <button
        className={`w-100 border-0 bg-white ${className}`}
        onClick={redirectToFidelityPage}
      >
        <FidelityBox />
      </button>
      {divider}
    </div>
  ) : null;
}

function FidelityBox() {
  const dispatch = useDispatch();
  const toast = useToast();
  const {
    shop: { id: storeId, fidelity },
    user: { userId, userUuid, userName, validOrders },
  } = useSelector((state) => state);
  const [isLoading, setLoading] = React.useState(true);

  const isLoggedUser = userId && userUuid;
  const hasDiscount = fidelity.ordersQuantity === validOrders.length;

  React.useEffect(() => {
    if (!isLoggedUser) {
      dispatch(setValidOrders({ validOrders: [] }));
      return;
    }
    setLoading(true);
    getValidOrders(userId, userUuid, storeId)
      .then((resp) => resp.json())
      .then((response) => {
        if (response.error) {
          throw new Error(
            response.error?.message ?? "Something wrong happened"
          );
        }

        const { pedidosValidos: validOrders } = response;
        dispatch(
          setValidOrders({
            validOrders,
          })
        );
      })
      .catch((error) => {
        console.error(error);
        toast.toastError("Erro ao consultar seus pontos de fidelidade.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, isLoggedUser, storeId, toast, userId, userUuid]);

  if (!isLoggedUser) {
    return (
      <Alert
        variant="warning"
        className="d-flex justify-content-between align-items-center mb-0"
      >
        <div className="d-flex align-items-center">
          <StarRoundedIcon fontSize="large" />
          <div className="d-flex flex-column text-left ml-2">
            <strong>Programa de Fidelidade!</strong>
            <small>Entre ou cadastra-se para participar</small>
          </div>
        </div>
        <KeyboardArrowRightRoundedIcon fontSize="large" />
      </Alert>
    );
  }

  if (hasDiscount) {
    return (
      <Alert
        variant="warning"
        className="d-flex justify-content-between align-items-center mb-0"
      >
        <div className="d-flex align-items-center">
          <StarRoundedIcon fontSize="large" />
          <div className="d-flex flex-column text-left ml-2">
            <div className="font-weight-bold">
              {`Olá, ${userName}, você tem ${toPriceDisplay(
                fidelity.discountValue,
                ","
              )} de desconto para usar no seu próximo pedido!`}
            </div>
          </div>
        </div>
      </Alert>
    );
  }

  const lastOrderDate =
    validOrders.length > 0
      ? new Date(validOrders[validOrders.length - 1].createdAt)
      : null;
  const nextValidDateTime = lastOrderDate
    ? lastOrderDate.setHours(lastOrderDate.getHours() + fidelity.orderInterval)
    : null;
  const waitInterval = nextValidDateTime && nextValidDateTime > new Date();

  return (
    <Alert
      variant="warning"
      className="d-flex flex-column align-items-center mb-0"
    >
      <div className="d-flex align-items-center w-100">
        <div className="d-flex align-items-center w-100">
          <StarRoundedIcon fontSize="large" />
          <div className="d-flex flex-column text-left ml-2 w-100">
            <div className="font-weight-bold">{`${fidelity.description}`}</div>
            <small>
              {`Olá, ${userName}, pedido mínimo de ${toPriceDisplay(
                fidelity.minimalPurchase,
                ","
              )}`}
            </small>
          </div>
        </div>
      </div>
      <ProgressBar
        fidelity={fidelity}
        validOrders={validOrders}
        isHidden={isLoading}
      />
      {waitInterval && (
        <div className="d-flex align-items-center w-100">
          <ReportProblemIcon />
          <small>
            {` Seu próximo pedido válido para o programa de fidelidade pederá ser feito após ${formatDateTime(
              nextValidDateTime
            )}`}
          </small>
        </div>
      )}
    </Alert>
  );
}

function ProgressBar({ fidelity, validOrders, isHidden }) {
  if (isHidden) {
    return (
      <div
        className="w-100 mt-2"
        style={{
          height: "6px",
        }}
      />
    );
  }

  return (
    <div className="d-flex my-3 w-100" style={{ columnGap: "6px" }}>
      {Array(fidelity.ordersQuantity)
        .fill(0)
        .map((_, index) => (
          <div
            key={uuidv4()}
            className="rounded"
            style={{
              background: index < validOrders.length ? "#ffa600" : "#ccc",
              height: "6px",
              width: `${Decimal(100)
                .dividedBy(fidelity.ordersQuantity)
                .toFixed(0)}%`,
            }}
          />
        ))}
    </div>
  );
}
