import React from "react";
import { useSelector } from "react-redux";
import Badge from "react-bootstrap/Badge";
import Figure from "react-bootstrap/Figure";
import { selectStatefulCosts } from "../ducks/checkoutSlice";
import toPriceDisplay from "../utils/toPriceDisplay";
import UnavailableDeliveryWarning from "./UnavailableDeliveryWarning";

export const ProductDivider = () => (
  <hr
    style={{
      borderTop: "2px solid #e6e6e6",
      margin: 0,
    }}
  />
);

export default function ProductsListCartTotem(props) {
  const costs = useSelector(selectStatefulCosts);
  const { products, renderOptions = null } = props;

  const itensCountTotal =
    products.length === 1
      ? "1 Item   Total "
      : `${products.length} Itens, Total: `;

  return (
    <div className="p-3">
      {products.map((product) => {
        const errorTextColor =
          product.error.isDisabled || product.error.isPriceChanged
            ? "text-danger"
            : null;
        return (
          <div className={`pb-3 ${errorTextColor}`} key={product.uuid}>
            <div className="pb-3 d-flex flex-row justify-content-between align-items-center">
              <div className="d-flex flex-row">
                {product.pictureUrl && (
                  <div className="d-flex flex-column mr-3 justify-content-center">
                    <Figure.Image
                      loading="lazy"
                      src={product.pictureUrl}
                      alt="Ilustração do produto"
                      fluid={false}
                      width={120}
                      rounded
                    />
                  </div>
                )}
                <div>
                  <p className="p-0 m-1 totem-cart-product-name">
                    {product.name}
                  </p>
                  {product.error.isDisabled && (
                    <Badge className="ml-1" variant="dark">
                      <small>INDISPONÍVEL</small>
                    </Badge>
                  )}

                  {product.detailsList && product.detailsList.length > 0 && (
                    <div className="p-0 mr-3">
                      {product.detailsList.map((detail, index) => (
                        <Badge
                          key={index}
                          variant="secondary"
                          className="m-1"
                          pill
                        >
                          <p className={`p-0 m-0 ${errorTextColor}`}>
                            {detail.name}
                          </p>
                          {detail.error.isDisabled && (
                            <Badge className="ml-1" variant="dark">
                              <small>INDISPONÍVEL</small>
                            </Badge>
                          )}
                        </Badge>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex flex-column align-items-center">
                <p className="m-0 mb-3 totem-cart-product-price">
                  {toPriceDisplay(product.price)}
                </p>
                {product.error.isPriceChanged && (
                  <Badge className="ml-1 mb-3" variant="secondary">
                    <small>{`${
                      product.error.priceDiff.gt(0) ? "+" : "-"
                    } ${toPriceDisplay(product.error.priceDiff.abs())}`}</small>
                  </Badge>
                )}
                {renderOptions(product)}
              </div>
            </div>
            <ProductDivider />
          </div>
        );
      })}
      <div className="m-0 d-flex flex-row font-weight-bold justify-content-end">
        <h4 className="align-self-end">
          <strong>{itensCountTotal}</strong>
        </h4>
        <h4 className="mx-3 totem-cart-total">
          {toPriceDisplay(costs.subtotal)}
        </h4>
      </div>
      <UnavailableDeliveryWarning />
    </div>
  );
}
