import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { red } from '@material-ui/core/colors';
import useGoTo from '../hooks/useGoTo';

const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(1),
        top: 'auto',
        left: 'auto',
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        color: theme.palette.common.white,
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[600],
        },
    },
    up: {
        bottom: '100px',
        marginBottom: 0,
        paddingBottom: 0,
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default function StatusButton({isCartVisible}) {
    const classes = useStyles();
    const goTo = useGoTo();
    const lastCache = useSelector(state => state.checkout.lastCheckoutCache);
    if (!lastCache) return null;
    // 36e5 is the scientific notation for 60*60*1000
    const hours = (new Date() - new Date(lastCache.createdAt)) / 36e5;
    if (hours > 3) return null;
    
    const handleClick = () => goTo(`/status/${lastCache.shortReference}`);

    return (
        <Fab 
            className={isCartVisible ? `${classes.fab} ${classes.up}` : classes.fab}
            variant="extended"
            onClick={handleClick}
        >
            <ReceiptIcon className={classes.extendedIcon} />
            {`Acompanhe seu pedido. Nº ${lastCache.shortReference}`}
        </Fab>
    );
}