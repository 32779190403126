import { useEffect, useRef, useState } from "react";
import throttle from "lodash.throttle";
import { useDispatch, useSelector } from "react-redux";
import { SECONDS_BEFORE_REST } from "../ducks/consts";
import { clearCart } from "../ducks/shoppingCartSlice";
import { clearCheckoutTotem } from "../ducks/checkoutSlice";
import { setActiveGroupId } from "../ducks/productsSlice";

export default function useResting() {
  // "controles" do modo de descanso, são efeitos colaterais muito delicados
  // que vagam praticamente por entre os detalhes de implementação do react,
  // estão com efeitos colaterais e limpezas bem definidos e otimizadas.
  const dispatch = useDispatch();
  const {
    isSelfcheckoutTotem,
    isSelfcheckoutPOS,
    uri,
    orderLocation,
    tableId,
  } = useSelector((state) => state.shop);

  const useRestWall = isSelfcheckoutTotem || isSelfcheckoutPOS;

  const [onRestMode, setOnRestMode] = useState(false);
  const lastInteractionRef = useRef(Date.now());

  useEffect(() => {
    if (!useRestWall) {
      return;
    }

    // mantém referência de última interação atualizada sem interferir
    // no ciclo de vida do react, exceto se for preciso DESATIVAR o modo de descanso.

    // const root = document.getElementById("root");
    const handleUserInteraction = throttle(() => {
      lastInteractionRef.current = Date.now();

      if (onRestMode) {
        console.log("Exiting rest mode, resuming.", new Date());
        setOnRestMode(false);
      }
    }, 500);

    const events = ["click", "touchmove", "wheel"];

    events.forEach((event) =>
      document.addEventListener(event, handleUserInteraction, { passive: true })
    );
    return () => {
      events.forEach((event) =>
        document.removeEventListener(event, handleUserInteraction)
      );
    };
  }, [useRestWall, onRestMode]);

  useEffect(() => {
    if (!useRestWall) {
      return;
    }

    // lê frequentemente a última interação do usuário sem fazer nada,
    // exceto se precisar ATIVAR modo de descanso

    if (onRestMode) {
      return;
    }

    const interval = setInterval(() => {
      const now = Date.now();
      const lastInteraction = lastInteractionRef.current;

      const diff = now - lastInteraction;

      const mustRest = diff >= SECONDS_BEFORE_REST * 1000;
      if (mustRest) {
        console.log("Reseting interaction, entering rest mode.", new Date());

        setOnRestMode(true);

        dispatch(setActiveGroupId(0));
        dispatch(clearCart());
        dispatch(clearCheckoutTotem());

        window.history.pushState(
          "home",
          "",
          `/${uri}/${orderLocation}/${tableId}`
        );
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [dispatch, useRestWall, uri, orderLocation, tableId, onRestMode]);

  return {
    onRestMode,
  };
}
