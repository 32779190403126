import React from "react";
import { useDispatch } from "react-redux";
import { clearCart } from "../../ducks/shoppingCartSlice";
import { clearCheckout, submitCheckout } from "../../ducks/checkoutSlice";
import useGoTo from "../../hooks/useGoTo";
import useToast from "../../hooks/useToast";
import QuestionCheckout from "../QuestionCheckout";

export default function SelfCheckoutQRCode() {
  const dispatch = useDispatch();
  const goTo = useGoTo();
  const { toastError } = useToast();

  const dispatchSelfCheckoutQRCode = async () => {
    try {
      const order = await dispatch(submitCheckout({}));
      dispatch(clearCart());
      dispatch(clearCheckout());
      goTo("/");
      goTo(`/status/${order.shortReference}`);
    } catch (err) {
      console.error(err);
      if (!err.isUserReadable) {
        toastError("Erro desconhecido tentar enviar pedido.", {
          autoClose: 6000,
        });
      }
    }
  };

  return (
    <QuestionCheckout
      takingHome={true}
      personalData={true}
      cpfQuestion={true}
      dispatchSubmitCheckout={dispatchSelfCheckoutQRCode}
    />
  );
}
