import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function useQueryParams() {
  const location = useLocation();

  if (localStorage === null || localStorage === undefined) {
    return {};
  }

  const queryParams = new URLSearchParams(location.search);
  const scrollParam = queryParams.get("scroll");
  if (scrollParam) {
    localStorage.setItem("scroll", scrollParam);
  }

  const showScroll =
    scrollParam === "manual" || localStorage.getItem("scroll") === "manual";

  return {
    showScroll,
  };
}
