import React from 'react';

const addLineBreaks = string =>
  string ? string.split('\n').map((text, index) => (
    <React.Fragment key={`${text}-${index}`}>
      {text}
      <br />
    </React.Fragment>
  )) : null;

  export default addLineBreaks;