import groupby from "lodash.groupby";

export function sliceFirebaseShop(loja) {
  try {
    return {
      id: loja["id"],
      uri: loja["appDeliveryUri"],
      cnpj: loja["cnpj"],
      name: loja["nome_fantasia"],
      phone: loja["telefone"],
      whatsapp: loja["whatsapp"],
      neighborhood: loja["bairro"],
      postalCode: loja["cep"],
      city: loja["cidade"],
      state: loja["uf"],
      manualAddressEnable: loja["appEnderecoManual"],
      addressStreet: loja["logradouro"],
      complement: loja["complemento"],
      addressNumber: loja["numero"],
      pictureUrl: getPictureUrl(loja["fotoUrl"]),
      informationHeader: loja["appInfoCabecalho"],
      informationFooter: loja["appInfoRodape"],
      emergencyPause: loja["pausaEmergencia"],
      workingScheduleInloco: getArrayWorkingTime(loja["horariosMesa"]),
      workingScheduleDelivery: getArrayWorkingTime(loja["horariosEntrega"]),
      workingScheduleToGo: getArrayWorkingTime(loja["horariosRetirada"]),
      takeoutEnable: loja["appRetirada"],
      takeoutDiscount: getDecimal(loja["appDescontoRetirada"]),
      deliveryEnable: loja["appEntrega"],
      deliveryMinimalCost: getDecimal(loja["appPedidoMinimo"]),
      deliveryFees: getArray(loja["taxasEntrega"]).map((fee) => ({
        id: fee["id"],
        enable: fee["status"],
        neighborhood: fee["bairro"],
        maxDistanceKms: fee["distancia"],
        estimatedMinutes: fee["minutos"],
        price: getDecimal(fee["valor"]),
      })),
      availablePaymentsToGo: getArray(loja["formasPagamentoRetirada"]).map(
        (p) => ({ id: p["id"], name: p["nome"] })
      ),
      availablePaymentsDelivery: getArray(loja["formasPagamentoEntrega"]).map(
        (p) => ({ id: p["id"], name: p["nome"] })
      ),
      mercadoPagoKey: loja["mercadoPagoKey"],
      mercadoPagoLogin: loja["mercadoPagoLogin"],
      mercadoPagoCartao: loja["mercadoPagoCartao"],
      mercadoPagoPix: loja["mercadoPagoPix"],
      pizzaPriceMode: loja["pizzaPreco"],
      messageOrderSuccess: loja["appMsgPedRealizado"],
      layoutGroupsFirst: loja["appGrupoLayout"],
      inLocoCmd: loja["appMesaComanda"],
      inLocoRequireToken: loja["appMesaExigeToken"],
      kilogramStep: loja["appKiloIncremento"],
      isWhatsappSendingEnabled: loja["appEnviaWhatsapp"],
      inLocoRequirePayment: loja["appMesaPagamento"],
      presentialPosPayment: loja["appMesaPagamentoPos"],
      fidelity: parseFidelityData(loja["fidelidade"]),
      isOrderSchedulingEnabled: loja["appAgendamento"] ?? false,
      daysAheadToSchedule: loja["appAgendamentoDias"] ?? 0,

      backgroudColor: loja["appBackgroudColor"],
      textColor: loja["appTextColor"],

      selfcheckoutConfigs: getArray(loja["autoatendimentoConfigs"]).map(
        (config) => ({
          id: config["id"],
          askForCpf: config["pergunta_cpf"],
          askForLocation: config["pergunta_embalagem"],
          askForName: config["pergunta_nome"],
          pictureUrlBanner1: getPictureUrl(config["fotoUrlBannerDescanso1"]),
          pictureUrlBanner2: getPictureUrl(config["fotoUrlBannerDescanso2"]),
          pictureUrlBanner3: getPictureUrl(config["fotoUrlBannerDescanso3"]),
        })
      ),
    };
  } catch (error) {
    console.error("Critical error on adapting Firebase shop data", error);
    throw new FirebaseAdapterError(loja, error);
  }
}

export function sliceFirebaseGroups(grupos) {
  try {
    return getArray(grupos)
      .sort((a, b) => a.ordem - b.ordem)
      .map((group) => ({
        id: group["id"],
        name: group["nome"],
        pizza: group["pizza"],
        image: getPictureUrl(group["fotoUrl"]),
        availabilities: getArrayWorkingTime(group["disponibilidades"]),
        disabled: group["status"] === false,
      }))
      .filter((g) => !g.disabled);
  } catch (error) {
    console.error("Critical error on adapting Firebase groups data", error);
    throw new FirebaseAdapterError(grupos, error);
  }
}

export function sliceFirebaseProducts(produtos) {
  try {
    return getArray(produtos)
      .filter((p) => p.id)
      .sort((a, b) => a.ordem - b.ordem || a.appNome.localeCompare(b.appNome))
      .map((product) => ({
        groupId: product["grupoId"],
        id: product["id"],
        tableVisible: product["appMesa"],
        deliveryVisible: product["appDelivery"],
        disabled: !product["stt_prod"],
        name: product["appNome"],
        pictureUrl: getPictureUrl(product["fotoUrl"]),
        basicPrice: getDecimal(product["appPreco"]),
        minPrice: getDecimal(product["precoMinimo"]),
        maxPrice: getDecimal(product["precoMaximo"]),
        details: product["appDetalhes"],
        pizza: product["pizza"],
        questionsIds: getArray(product["perguntas"]),
        isHighlight: !!product["appDestaque"],
        availabilities: getArrayWorkingTime(product["disponibilidades"]),
        isKilogram: product["kilo"],
        isAlcoholic: product["alcoolico"],
      }))
      .filter((p) => !p.disabled);
  } catch (error) {
    console.error("Critical error on adapting Firebase products data", error);
    throw new FirebaseAdapterError(produtos, error);
  }
}

export function parseShopList(shops) {
  return getArray(shops).map((shop) => sliceFirebaseShop(shop));
}

export function parseProductQuestions(perguntas) {
  try {
    return getArray(perguntas).map((question) => ({
      id: question["id"],
      description: question["descricao"],
      minQtt: question["qtd_min"],
      maxQtt: question["qtd_max"],
      isAllowedOptionRepetition: question["repetir_resposta"],
      pizza: question["pizza"],
      pizzaPriceMode: question["pizzaPreco"],
      options: getArray(question["respostas"] || [])
        .filter((o) => o.id)
        .sort((a, b) => a.ordem - b.ordem || a.nome.localeCompare(b.nome))
        .map((o) => ({
          id: o["id"],
          disabled: !o["ativo"],
          label: o["nome"],
          details: o["detalhes"],
          price: getDecimal(o["valor"]),
          pictureUrl: getPictureUrl(o["fotoUrl"]),
          comboItem: o["comboItem"],
        }))
        .filter((o) => !o.disabled),
    }));
  } catch (error) {
    console.error("Critical error on adapting Firebase questions data", error);
    throw new FirebaseAdapterError(perguntas, error);
  }
}

const getPictureUrl = (url) => {
  return encodeURI(url || "");
};

const getArrayWorkingTime = (arr) => {
  return groupby(
    getArray(arr)
      .map((w) => parseWorkingTime(w))
      .reduce(
        (acc, parsed) => [
          ...acc,
          ...(parsed.start.hours > parsed.end.hours
            ? [
                {
                  start: parsed.start,
                  end: {
                    hours: 23,
                    minutes: 59,
                  },
                  weekDay: parsed.weekDay,
                },
                {
                  start: {
                    hours: 0,
                    minutes: 0,
                  },
                  end: parsed.end,
                  weekDay: parsed.weekDay === 6 ? 0 : parsed.weekDay + 1,
                },
              ]
            : [parsed]),
        ],
        []
      ),
    "weekDay"
  );
};
const stringDateToRaw = (stringifiedDatetime) => {
  const date = new Date(stringifiedDatetime);
  return {
    hours: date.getHours(),
    minutes: date.getMinutes(),
  };
};
const parseWorkingTime = (arrayItem) => {
  return {
    start: stringDateToRaw(arrayItem["inicio"]),
    end: stringDateToRaw(arrayItem["fim"]),
    weekDay: parseInt(arrayItem["dia_semana"], 10),
  };
};
const getDecimal = (value) => (value ? value.toString() : "0");
const getArray = (value) => {
  if (!value) {
    return [];
  } else if (Array.isArray(value)) {
    return value.filter((v) => v);
  } else {
    const keys = Object.keys(value);
    return keys.map((k) => value[k]).filter((v) => v);
  }
};
const parseFidelityData = (fidelityData) => {
  if (!fidelityData) {
    return;
  }

  const {
    descricao: description,
    id,
    lojaId: storeId,
    pedido_intervalo_horas: orderInterval,
    pedido_valor_minimo: minimalPurchase,
    prazo_dias: expiration,
    quantidade_pedidos: ordersQuantity,
    valor_desconto: discountValue,
  } = fidelityData;

  return {
    description,
    id,
    storeId,
    orderInterval,
    minimalPurchase,
    expiration,
    ordersQuantity,
    discountValue,
  };
};

class FirebaseAdapterError extends Error {
  constructor(data, causedBy) {
    super("Error on trying to adapt Firebase response data.");
    this.data = data;
    this.causedBy = causedBy;
  }
}
