import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import { setInLocoConfirmed, inLocoValidateToken } from "../ducks/shopSlice";
import Loading from "../components/Loading";

const styles = {
  tableLabel: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: 1,
    margin: 0,
    padding: 0,
  },
  tableId: {
    fontSize: "64px",
    fontWeight: "bold",
    lineHeight: 1,
    marginTop: "50px",
    marginBottom: "50px",
    padding: 0,
  },
  comandaLabel: {
    fontSize: "64px",
    fontWeight: "bold",
    lineHeight: 1,
    marginTop: "50px",
    padding: 0,
  },
};

export default function TableConfirmationModal() {
  const {
    inLocoCmd,
    isInLocoConfirmed,
    inLocoText,
    inLocoTitle,
    tableId,
    deliveryEnable,
    takeoutEnable,
    inLocoRequireToken,

    isValidatingToken,
    tokenError,
  } = useSelector((state) => state.shop);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    if (inLocoRequireToken && token) {
      queryParams.delete("token");
      history.replace({
        search: queryParams.toString(),
      });
      dispatch(inLocoValidateToken(token));
    }
  }, [dispatch, location, history, inLocoRequireToken]);

  const confirmInLoco = () => {
    dispatch(setInLocoConfirmed({ isInLocoConfirmed: true }));
  };

  return (
    <Modal show={!isInLocoConfirmed} backdrop="static" onHide={confirmInLoco}>
      <Modal.Body className="d-flex flex-column align-items-center text-center">
        {inLocoCmd && (
          <>
            <p style={styles.tableLabel}>Os pedidos serão marcados na:</p>
            <p style={styles.comandaLabel}>{inLocoTitle}</p>
            <p style={styles.tableId}>{tableId}</p>
          </>
        )}
        {!inLocoCmd && (
          <>
            <p style={styles.tableLabel}>Os pedidos serão entregues em:</p>
            <p style={styles.tableId}>{inLocoText}</p>
          </>
        )}
        {tokenError && <p className="text-danger mx-5">{tokenError}</p>}
        {isValidatingToken && <Loading message={`Liberando ${inLocoTitle}`} />}
      </Modal.Body>
      <Modal.Footer
        className={
          deliveryEnable || takeoutEnable
            ? "d-flex flex-row align-items-center"
            : ""
        }
      >
        <Button
          variant="danger"
          onClick={confirmInLoco}
          disabled={isValidatingToken}
        >
          Ver cardápio
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
