import React from "react";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import useGoTo from "../hooks/useGoTo";
import { getGroupAvailability } from "../hooks/useWorkingTime";
import GroupedProductsView from "./GroupedProductsView";
import GroupCard from "../components/GroupCard";

export default function GroupsListView() {
  const goTo = useGoTo();
  const search = useSelector((state) => state.search);
  const { groups } = useSelector((state) => state.products);
  if (search.content && search.content.length > 3) {
    return <GroupedProductsView />;
  }

  const onGroupSelect = (groupId) => goTo(`/group/${groupId}`);

  const selectGroup = (groupId) => () => onGroupSelect(groupId);

  return (
    <Container>
      {groups
        .filter((g) => getGroupAvailability(g).isAvailable)
        .map((group) => (
          <GroupCard group={group} onClick={selectGroup(group.id)} />
        ))}
    </Container>
  );
}
