import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Badge from "react-bootstrap/Badge";
import addLineBreaks from "../utils/addLineBreaks";
import { PRICE_MODE_MAX } from "../ducks/shoppingCartSlice";
import CommonHeader from "../containers/CommonHeader";
import FloatingProductOrderFooter from "../containers/FloatingProductOrderFooter";
import productUnitPriceDisplay from "../utils/productPriceDisplay";

export default function ProductWithQuestionsTotem({
  onBack,
  product,
  pizzaPriceMode,
  isOrderVisible,
  orderUuid,
  orderUuidEdit,
}) {
  return (
    <>
      <div className={`product-screen--stretched"}`}>
        <CommonHeader text={product.name} onBack={onBack} />
        <ProductCard product={product} pizzaPriceMode={pizzaPriceMode} />
      </div>
      <FloatingProductOrderFooter
        isOrderVisible={isOrderVisible}
        orderUuid={orderUuid}
        orderUuidEdit={orderUuidEdit}
      />
    </>
  );
}

const ProductCard = ({ product, pizzaPriceMode }) => (
  <Container>
    <Card className="border-0">
      {product.pictureUrl && (
        <Card.Img
          src={product.pictureUrl}
          alt="Ilustração do produto"
          variant="top"
          className="totem-product-image"
        />
      )}
      <Card.Body>
        <Card.Text className="my-3 totem-product-name">
          {product.name}
        </Card.Text>
        {product.disabled && (
          <Badge className="mb-3" variant="secondary">
            <small>INDISPONÍVEL</small>
          </Badge>
        )}
        <Card.Text className="totem-product-details">
          {addLineBreaks(product.details)}
        </Card.Text>
        <Card.Text className="my-3 totem-product-price">
          {productUnitPriceDisplay(product)}
        </Card.Text>
        {product.pizza && pizzaPriceMode === PRICE_MODE_MAX && (
          <Card.Text className="text-muted small">
            A pizza com vários sabores é cobrada pelo preço do sabor mais caro.
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  </Container>
);
