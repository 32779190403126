export default function domScrollTo(top, behavior = "smooth") {
  try {
    window.document.documentElement.scrollTo({ top, behavior });
  } catch {
    console.error("Scroll unavailable for `window.document.documentElement`.");
  }
}

export function domScrollToBottom(behavior = "smooth") {
  try {
    const top = window.document.body.scrollHeight;
    window.document.documentElement.scrollTo({ top, behavior });
  } catch {
    console.error("Scroll unavailable for `window.document.documentElement`.");
  }
}

export function elementScrollTo(id, top, behavior = "smooth") {
  try {
    const scrollingElement = window.document.getElementById(id);
    if (scrollingElement) {
      scrollingElement.scrollTo({ top, behavior });
    } else {
      console.error(`Scroll unavailable, id not found ${id}.`);
    }
  } catch {
    console.error("Scroll unavailable for `window.document.documentElement`.");
  }
}
