import React from "react";
import { useSelector } from "react-redux";
import get from "lodash.get";
import last from "lodash.last";
import findLast from "lodash.findlast";
import groupBy from "lodash.groupby";
import debounce from "lodash.debounce";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import domScrollTo from "../utils/domScrollTo";
import ProductsList from "../components/ProductsList";
import useGoTo from "../hooks/useGoTo";
import {
  getGroupAvailability,
  getProductAvailability,
} from "../hooks/useWorkingTime";

export default function GroupedProductsView() {
  const goTo = useGoTo();
  const search = useSelector((state) => state.search);
  const { groups, items: products } = useSelector((state) => state.products);
  const groupsRefs = React.useRef({});
  const [activeGroupId, setActiveGroupId] = React.useState(null);
  const [isNavFixedAtTop, setIsNavFixedToTop] = React.useState(false);

  groups.forEach((group) => {
    if (!groupsRefs.current[group.id]) {
      groupsRefs.current[group.id] = React.createRef();
    }
  });

  React.useEffect(() => {
    const currentGroupsRefs = groupsRefs.current;
    if (!groups.length || !Object.keys(currentGroupsRefs).length) {
      return;
    }

    const positionedGroups = groups.map((group) => ({
      id: group.id,
      position: get(currentGroupsRefs, `${group.id}.current.offsetTop`, 0),
    }));

    const handler = debounce(() => {
      const windowPosition = getWindowScrollPosition();
      const isAtTop = windowPosition < 50;
      const visuallyactiveGroup = isWindowScrollAtEnd()
        ? last(positionedGroups)
        : findLast(positionedGroups, (g) => windowPosition > g.position - 250);

      setActiveGroupId(get(visuallyactiveGroup, "id") || null);
      setIsNavFixedToTop(isAtTop ? false : !!get(visuallyactiveGroup, "id"));
    }, 50);

    window.addEventListener("scroll", handler, true);
    return () => window.removeEventListener("scroll", handler, true);
  }, [groups, activeGroupId]);

  const goToGroup = (groupId) => {
    const groupContainer = groupsRefs.current[groupId];
    if (!groupContainer) {
      return;
    }

    domScrollTo(get(groupContainer.current, "offsetTop", 0) - 70);
  };

  const availableProducts = products.filter(
    (p) => getProductAvailability(p).isAvailable
  );

  const filteredProducts = availableProducts.filter(
    (p) =>
      (p.name || "").toLowerCase().includes(search.content.toLowerCase()) ||
      (p.details || "").toLowerCase().includes(search.content.toLowerCase())
  );
  const filteredProductsByGroup = groupBy(filteredProducts, "groupId");

  const onProductSelect = (productId) => goTo(`/product/${productId}`);

  return (
    <div>
      {isNavFixedAtTop && (
        <Navbar bg="shop" fixed="top" className="scroll">
          <Nav>
            {groups
              .filter(
                (g) =>
                  filteredProductsByGroup[g.id] &&
                  getGroupAvailability(g).isAvailable
              )
              .map((group) => (
                <Nav.Item key={group.id} onClick={() => goToGroup(group.id)}>
                  <Nav.Link
                    href="#"
                    className={`text-shop${
                      group.id === activeGroupId ? " font-weight-bold" : ""
                    }`}
                  >
                    {group.name}
                  </Nav.Link>
                </Nav.Item>
              ))}
          </Nav>
        </Navbar>
      )}
      <Container className="p-0 m-0">
        {groups
          .filter(
            (g) =>
              filteredProductsByGroup[g.id] &&
              getGroupAvailability(g).isAvailable
          )
          .map((group) => (
            <div key={group.id} ref={groupsRefs.current[group.id]}>
              <h2 className="font-weight-bold mt-4 mb-4 ml-3">{group.name}</h2>
              <ProductsList
                products={filteredProductsByGroup[group.id]}
                onProductSelect={onProductSelect}
              />
            </div>
          ))}
      </Container>
    </div>
  );
}

const getWindowScrollPosition = () => window.document.documentElement.scrollTop;

const isWindowScrollAtEnd = () =>
  document.documentElement.scrollHeight -
    window.document.documentElement.scrollTop -
    40 <=
  document.documentElement.clientHeight;
