export default function Avatar({ name, className = "" }) {
  if (!name) {
    return null;
  }

  return (
    <div className={`avatar ${className}`}>
      <span className="avatar_initials">{getInitials(name)}</span>
    </div>
  );
}

const getInitials = (name) => {
  return name
    .split(" ")
    .filter((word) => word.length > 2)
    .map((word) => word[0])
    .slice(0, 3)
    .join("");
};
