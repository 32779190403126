import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import CommonHeader from "../containers/CommonHeader";
import useGoTo from "../hooks/useGoTo";
import { logoff } from "../ducks/userSlice";
import phoneDisplay from "../utils/phoneDisplay";
import formatDate from "../utils/formatDate";

export default function FidelityScreen() {
  const dispatch = useDispatch();
  const goTo = useGoTo();
  const {
    shop: { fidelity },
    user: { userId, userUuid, userPhone, userName, userBirthday },
  } = useSelector((state) => state);
  const { description, expiration, orderInterval } = fidelity;
  const intervalText =
    orderInterval > 1 ? `${orderInterval} horas` : `${orderInterval} hora`;

  const isLoggedUser = !!(userId && userUuid);

  const goToLogin = () => goTo("/login");
  const userLogoff = () => {
    dispatch(logoff());
    goTo("/");
  };

  return (
    <>
      <CommonHeader text="Programa de Fidelidade" />
      <div className="mx-3 mx-lg-0">
        <div className="w-100 text-center mt-3 font-weight-bold">
          {description}
        </div>
        <div className="mt-3">
          <div className="font-weight-bold">Validade</div>
          <span className="text-muted">{`Complete o Programa de Fidelidade em até ${expiration} dias`}</span>
        </div>
        <div className="mt-3">
          <div className="font-weight-bold">Regras</div>
          <ul>
            <li className="text-muted">
              O desconto será aplicado automaticamente na compra seguinte à
              conclusão do Programa de Fidelidade.
            </li>
            <li className="text-muted">
              Para que as compras sejam válidas no Programa de Fidelidade o
              usuário deve estar logado no momento da compra.
            </li>
            <li className="text-muted">
              Para utilizar o desconto do Programa de Fidelidade, o usuário deve
              estar logado.
            </li>
            <li className="text-muted">
              Apenas pedidos válidos e confirmados pela loja são contabilizados
              no Programa.
            </li>
            <li className="text-muted">{`Pedidos válidos para o Programa de Fidelidade devem feitos com ${intervalText} de intervalo.`}</li>
          </ul>
        </div>
        {isLoggedUser && (
          <div className="mt-3">
            <div className="font-weight-bold">Dados Pessoais</div>
            <ul>
              <li className="text-muted">{`Nome: ${userName}`}</li>
              <li className="text-muted">{`Celular: ${phoneDisplay(
                userPhone
              )}`}</li>
              <li className="text-muted">
                {`Data de nascimento: ${formatDate(userBirthday)}`}
              </li>
            </ul>
          </div>
        )}
        {isLoggedUser && (
          <div className="fixed-bottom p-3">
            <Button className="w-100" variant="danger" onClick={userLogoff}>
              Sair do programa de fidelidade
            </Button>
          </div>
        )}
        {!isLoggedUser && (
          <div className="fixed-bottom p-3">
            <Button className="w-100" variant="danger" onClick={goToLogin}>
              Entrar e participar
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
