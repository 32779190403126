import React from "react";
import { useHistory } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import useGoTo from "../hooks/useGoTo";
import selfcheckoutTotem from "../utils/selfcheckoutTotem";

export default function CommonHeader({
  text = "",
  onBack = () => {},
  showBack = true,
  className,
}) {
  const isSelfcheckoutTotem = selfcheckoutTotem();
  const history = useHistory();
  const goTo = useGoTo();
  const onBackClick = () => {
    const signalToProceed = onBack();
    if (signalToProceed !== undefined && signalToProceed === false) {
      return;
    }

    if (history.action === "PUSH") {
      history.goBack();
    } else {
      goTo("/");
    }
  };

  const buttonClassName = isSelfcheckoutTotem ? "p-4 totem-back-button" : "";

  const navbarClassName = isSelfcheckoutTotem
    ? "w-100 py-4 m-0 totem-header"
    : "w-100 py-3 m-0 text-navbar";

  return (
    <Navbar
      variant="light"
      bg="light"
      sticky="top"
      className={`p-0 m-0 ${className}`}
    >
      {showBack && (
        <Button
          className={buttonClassName}
          variant="link"
          onClick={onBackClick}
          style={{ position: "absolute" }}
        >
          <KeyboardBackspaceIcon />
        </Button>
      )}
      <Navbar.Brand className={navbarClassName}>{text}</Navbar.Brand>
    </Navbar>
  );
}
