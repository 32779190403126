import React from "react";
import Decimal from "decimal.js";
import { useSelector, useDispatch } from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import EditIcon from "@material-ui/icons/Edit";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ProductsListCart from "../components/ProductsListCart";
import { cancelOrder } from "../ducks/shoppingCartSlice";
import useGoTo from "../hooks/useGoTo";
import { requireProducts } from "../ducks/productsSlice";
import { STATE_STEP } from "../ducks/consts";
import Loading from "../components/Loading";
import { getProductAvailability } from "../hooks/useWorkingTime";
import ProductsListCartTotem from "../components/ProductsListCartTotem";

export default function CartView() {
  const goTo = useGoTo();
  const dispatch = useDispatch();
  const questionsStateByProduct = useSelector(
    (state) => state.products.questionsStateByProduct
  );
  const {
    shop,
    products,
    questionsByProduct,
    orders,
    cachedCostPerOrder,
    cachedCostPerOrderOld,
    answersByOrder,
  } = useSelector((state) => ({
    shop: state.shop,
    products: state.products.items,
    questionsByProduct: state.products.questionsByProduct,
    orders: state.shoppingCart.productOrders.filter((o) => o.commited),
    cachedCostPerOrderOld: state.shoppingCart.cachedCostPerOrderOld,
    cachedCostPerOrder: state.shoppingCart.cachedCostPerOrder,
    answersByOrder: state.shoppingCart.answersByOrder,
    submitError: state.checkout.submitError,
  }));

  const orderedProducts = orders.map((order) => {
    const productId = order.productId;
    const productQuestions = questionsByProduct[productId] || [];

    const detailsList = answersByOrder[order.uuid].map((a) => {
      const question = a.question;
      const label = question.options.find((o) => o.id === a.optionId).label;
      const name =
        question.pizza && question.pizzaPriceMode
          ? `${a.qtt}/${question.maxQtt} - ${label}`
          : `${a.qtt}x ${label}`;
      return {
        name,
        error: optionErrors(productQuestions, a),
      };
    });

    if (order.observations) {
      detailsList.push({
        name: `Observação: ${order.observations}`,
        error: {
          isDisabled: false,
        },
      });
    }

    return {
      uuid: order.uuid,
      id: productId,
      price: cachedCostPerOrder[order.uuid],
      name: `${order.qtt}x ${order.product.name}`,
      detailsList,
      deliveryVisible: order?.product?.deliveryVisible ?? false,
      pictureUrl: order.product.pictureUrl,
      error: productErrors(
        order,
        products,
        cachedCostPerOrderOld,
        cachedCostPerOrder
      ),
    };
  });

  React.useEffect(() => {
    orders.forEach((order) => dispatch(requireProducts(order.productId)));
  }, [dispatch, orders]);

  if (orders.length === 0) {
    goTo("/");
    return (
      <Alert variant="secondary" className="text-center mx-3 mt-3">
        Sacola vazia. Volte e escolha algum produto!
      </Alert>
    );
  }

  const isLoading = Object.keys(questionsStateByProduct)
    .map((key) => questionsStateByProduct[key])
    .some((step) => step === STATE_STEP.LOADING);

  if (isLoading) {
    return <Loading message="Carregando carrinho..." />;
  }

  if (shop.isSelfcheckoutTotem) {
    return (
      <ProductsListCartTotem
        products={orderedProducts}
        renderOptions={(requested) => (
          <OptionsProductButton
            productOrder={requested}
            isSelfcheckoutTotem={shop.isSelfcheckoutTotem}
          />
        )}
      />
    );
  }

  return (
    <ProductsListCart
      isSelfcheckoutTotem={shop.isSelfcheckoutTotem}
      products={orderedProducts}
      renderOptions={(requested) => (
        <OptionsProductButton productOrder={requested} />
      )}
    />
  );
}

const OptionsProductButton = ({ productOrder, isSelfcheckoutTotem }) => {
  const dispatch = useDispatch();
  const goTo = useGoTo();
  const [open, setOpen] = React.useState(false);

  const remove = () => {
    setOpen(false);
    dispatch(cancelOrder({ uuid: productOrder.uuid }));
  };

  const edit = () => {
    setOpen(false);
    goTo(`/product/${productOrder.id}/order/${productOrder.uuid}`);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {isSelfcheckoutTotem && (
        <Button variant="outline-secondary" onClick={handleClickOpen}>
          opções
        </Button>
      )}
      {!isSelfcheckoutTotem && (
        <Button className="p-0" variant="link" onClick={handleClickOpen}>
          <MoreVertIcon />
        </Button>
      )}
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>{productOrder.name}</DialogTitle>
        {!productOrder.error.isDisabled && (
          <Button variant="link" className="p-3" onClick={edit}>
            <EditIcon className="mr-2" />
            Editar
          </Button>
        )}
        <Button variant="link" className="p-3" onClick={remove}>
          <RemoveShoppingCartIcon className="mr-2" />
          Remover
        </Button>
      </Dialog>
    </div>
  );
};

function productErrors(
  order,
  products,
  cachedCostPerOrderOld,
  cachedCostPerOrder
) {
  const product = products.find((p) => p.id === order.productId);
  if (product) {
    const isDisabled = product.disabled;
    const isAvailable = getProductAvailability(product).isAvailable;
    const priceDiff = cachedCostPerOrderOld[order.uuid]
      ? Decimal(cachedCostPerOrder[order.uuid]).minus(
          cachedCostPerOrderOld[order.uuid]
        )
      : Decimal(0);
    return {
      isDisabled: isDisabled || !isAvailable,
      isPriceChanged: !priceDiff.equals(0),
      priceDiff,
    };
  }

  return {
    isDisabled: true,
  };
}

function optionErrors(questions, answeredOption) {
  const fixedQuestion = questions.find(
    (q) => q.id === answeredOption.questionId
  );
  if (fixedQuestion) {
    const fixedOption = fixedQuestion.options.find(
      (o) => o.id === answeredOption.optionId
    );
    if (fixedOption) {
      return {
        isDisabled: fixedOption.disabled,
      };
    }
  }

  return {
    isDisabled: true,
  };
}
