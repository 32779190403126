export function minimalDelayedHOC(func, threshold = 1000) {
  const initialTime = Date.now();
  const thresholdTime = initialTime + threshold;

  return function minimalDelayedDo(...args) {
    return new Promise((resolve, reject) => {
      const pendingTime = thresholdTime - Date.now();
      if (pendingTime > 0) {
        global.setTimeout(() => {
          try {
            resolve(func(...args));
          } catch (error) {
            reject(error);
          }
        }, pendingTime);
      } else {
        try {
          resolve(func(...args));
        } catch (error) {
          reject(error);
        }
      }
    });
  }
}
