import React from "react";
import { useSelector } from "react-redux";
import StorefrontIcon from "@material-ui/icons/Storefront";
import Alert from "react-bootstrap/Alert";

export default function UnavailableDeliveryWarning() {
  const { toDelivery, orders } = useSelector((state) => ({
    toDelivery: state.checkout.toDelivery,
    orders: state.shoppingCart.productOrders.filter((o) => o.commited),
  }));

  const unavailableProdsNames = orders
    .filter((order) => !order.product.deliveryVisible)
    .map((order) => order.product.name);

  if (unavailableProdsNames.length === 0 || !toDelivery) {
    return null;
  }

  const productUniqueNames = [...new Set(unavailableProdsNames)];
  const productNames = productUniqueNames.join(", ");
  const message =
    productUniqueNames.length === 1
      ? " disponível somente para retirada no balcão."
      : " disponíveis somente para retirada no balcão.";

  return (
    <Alert variant="info m-0 mt-3">
      <div className="d-flex align-items-center">
        <StorefrontIcon fontSize="large" />
        <div className="text-left ml-2">
          <strong>{productNames}</strong>
          {message}
        </div>
      </div>
    </Alert>
  );
}
