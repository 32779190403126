import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Figure from "react-bootstrap/Figure";
import Badge from "react-bootstrap/Badge";
import addLineBreaks from "../utils/addLineBreaks";
import productUnitPriceDisplay from "../utils/productPriceDisplay";

export default function ProductsListTotem(props) {
  const { group, products, onProductSelect = () => {} } = props;
  if (!products) {
    return (
      <div className="d-flex flex-column text-center my-5">
        <p>Ops, nenhum produto encontrado...</p>
      </div>
    );
  }

  const cols = window.screen.width > window.screen.height ? 3 : 2;

  return (
    <div>
      <h2 className="font-weight-bold totem-home-product-name mt-5 mb-4 ml-3">
        {group.name}
      </h2>
      {splitProductsInRows(products, cols).map((productsInRow) => (
        <Row key={makeProductsRowKey(productsInRow)} className="mx-1 mb-5">
          {productsInRow.map((product) => (
            <Col
              className={`shadow bg-white mx-3`}
              key={product.id}
              onClick={() => onProductSelect(product.id)}
            >
              {product.pictureUrl && (
                <Figure.Image
                  src={product.pictureUrl}
                  alt="Imagem do produto"
                  fluid={false}
                  className="w-100 totem-home-product-image"
                  rounded
                />
              )}
              <ProductName product={product} />
              <ProductBadges product={product} />
              <ProductDetails product={product} />
              <ProductPrice product={product} />
            </Col>
          ))}
          {productsInRow.length < cols &&
            [...Array(cols - productsInRow.length)].map((e, i) => (
              <Col className="mx-3" />
            ))}
        </Row>
      ))}
    </div>
  );
}

function ProductName({ product }) {
  return (
    <p className="my-3 text-center totem-home-product-name">{product.name}</p>
  );
}

function ProductBadges({ product }) {
  if (!product.disabled) {
    return null;
  }

  return (
    <Badge className="mb-3" variant="secondary">
      <small>INDISPONÍVEL</small>
    </Badge>
  );
}

function ProductDetails({ product }) {
  if (!product.details) {
    return null;
  }

  return (
    <p className="m-0 totem-home-product-details">
      {addLineBreaks(product.details)}
    </p>
  );
}

function ProductPrice({ product }) {
  return (
    <p className="my-3 mb-0 totem-home-product-price product-price-color-shop">
      {productUnitPriceDisplay(product)}
    </p>
  );
}

function splitProductsInRows(products, rowSize) {
  return products.reduce((rows, product, index) => {
    const isNewPage = index % rowSize === 0;
    if (isNewPage) {
      return [...rows, [product]];
    }

    const tailRow = rows[rows.length - 1];
    const otherRows = rows.slice(0, rows.length - 1);
    return [...otherRows, [...tailRow, product]];
  }, []);
}

function makeProductsRowKey(products) {
  return products.map((it) => `product(${it.id})`).join("+");
}
