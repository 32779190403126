import { createSlice } from "@reduxjs/toolkit";
import { parseShopList } from "../utils/firebase-adapters";

const initialState = Object.freeze({
  name: "",
  description: "",
  shops: [],
});

export const marketplaceSlice = createSlice({
  name: "marketplace",
  initialState,
  reducers: {
    setMarketplaceShops: (state, { payload }) => {
      state.shops = payload.shops;
    },
  },
});

export const loadMarketplace = async (namespace, dispatch) => {
  const shopsResponse = await fetch(
    `https://pallas-delivery.firebaseio.com/listas/${namespace}.json`
  );
  if (shopsResponse.status !== 200) {
    throw new Error('Endpoint of "listas" is not ok.');
  }

  const shopsJson = await shopsResponse.json();
  const shops = parseShopList(shopsJson);

  dispatch(marketplaceSlice.actions.setMarketplaceShops({ shops }));
};

export default marketplaceSlice.reducer;
