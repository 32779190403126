import has from 'lodash.has';
import pick from 'lodash.pick';

export default function pickT(obj, ...keys) {
  if (!obj || typeof obj !== 'object') {
    throw new TypedPickError('Invalid type', obj, keys);
  }

  keys.forEach((key) => {
    if (!has(obj, key)) {
      throw new TypedPickError(key, obj, keys);
    }
  });

  return pick(obj, ...keys);
};

class TypedPickError extends Error {
  constructor(message, obj, keys) {
    super(`Tried to pick a predefined set of keys but failed: ${message}`);
    this.obj = obj;
    this.keys = keys;
  }
}
