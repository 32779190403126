import get from "lodash.get";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setCloseError } from "../ducks/checkoutSlice";

export default function CheckoutServerError() {
  const dispatch = useDispatch();
  const { isErrorVisible, submitError } = useSelector(
    (state) => state.checkout
  );

  const errorStatus = get(submitError, "details.status") ? true : false;
  const errorPrice =
    get(submitError, "details.labels.length") === 0 ? true : false;
  const errorDisable =
    get(submitError, "details.labels.length") > 0 ? true : false;
  const errorGeneric = submitError && !submitError.details;

  const cancelError = () => dispatch(setCloseError());

  return (
    <Modal show={isErrorVisible && submitError} onHide={cancelError}>
      <Modal.Header closeButton>
        <Modal.Title>Ops...</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3 m-3">
        {errorGeneric && (
          <div>
            <p>{submitError.message}</p>
          </div>
        )}
        {errorStatus && (
          <div>
            <p>Infelizmente a loja não pode receber o pedido.</p>
            <p>{`Motivo: ${submitError.message}`}</p>
          </div>
        )}
        {errorPrice && (
          <div>
            <p>Alguns produtos mudaram de preço e já atualizamos sua sacola.</p>
            <p>
              Caso esteja tudo bem com os novos preços é só finalizar o pedido
              novamente.
            </p>
          </div>
        )}
        {errorDisable && (
          <div>
            <p>
              Os produtos listados abaixo estão indisponíveis no momento, por
              favor remova-os da sacola.
            </p>
            <ul>
              {submitError.details.labels.map((label, index) => (
                <li key={index}>{label}</li>
              ))}
            </ul>
            <p>
              Já atualizamos o menu da loja caso você queira adicionar mais
              produtos.
            </p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="shop" onClick={cancelError}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
