import { useEffect } from "react";

const useMPSsecurity = (mercadoPagoKey) => {
  useEffect(() => {
    const isAutoatendimento =
      window.location.pathname.includes("autoatendimento");
    if (!mercadoPagoKey || isAutoatendimento) {
      return;
    }

    const script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.async = false;
    script2.innerHTML = `window.mercadoPago = new MercadoPago('${mercadoPagoKey}');`;

    const script1 = document.createElement("script");
    script1.src = "https://sdk.mercadopago.com/js/v2";
    script1.async = false;
    script1.addEventListener("load", () => {
      setTimeout(() => document.body.appendChild(script2), 2000);
    });

    document.body.appendChild(script1);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, [mercadoPagoKey]);
};

export default useMPSsecurity;
