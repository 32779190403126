export default function Divider(props) {
  return (
    <hr
      style={{
        borderTop: "10px solid #e6e6e6",
        margin: 0,
      }}
      {...props}
    />
  );
}
