import { createSlice } from '@reduxjs/toolkit';

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    content: '',
  },
  reducers: {
    setFilterByContent: (state, { payload }) => {
      state.content = payload.content;
    },
  },
});

export const { setFilterByContent } = searchSlice.actions;

export default searchSlice.reducer;
