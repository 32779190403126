import React from "react";
import get from "lodash.get";
import { connect } from "react-redux";
import ErrorWall from "./ErrorWall";
import WhatsAppButton from "./WhatsAppButton";
import { notifyErrorTracker } from "../services/error-tracker";

class UnknownErrorGuard extends React.PureComponent {
  state = {
    error: null,
  };

  componentDidCatch(error) {
    console.log("set state error");
    console.log(error);
    console.log(JSON.stringify(error));

    if (this.state.error) {
      return;
    }

    notifyErrorTracker(error);

    this.setState({ error: error || true });
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorWall title="Ops...">
          <p>
            Um erro desconhecido aconteceu e o aplicativo parou de funcionar.
            Para realizar seu pedido entre em contato direto com a loja.
          </p>
          <p>{`Telefone: ${this.props.phone}`}</p>
          <WhatsAppButton whatsapp={this.props.whatsapp} />

          <p>
            <small>{this.state.error.toString()}</small>
            {/* <br />
            <br />
            <small>{this.state.error.stack}</small>
            <br />
            <br />
            <small>{JSON.stringify(this.state.error)}</small> */}
          </p>
        </ErrorWall>
      );
    }

    return this.props.children;
  }
}

export default connect((state) => ({
  phone: get(state, "shop.phone"),
  whatsapp: get(state, "shop.whatsapp"),
}))(UnknownErrorGuard);
