import {
  API_PALLAS_APPS_URL,
  FIREBASE_BASE_URL,
  PROMISE_TIMEOUT,
} from "../ducks/consts";
import timeoutError from "./timeoutError";

const TIMEOUT_MS = 5000;

export default async function getFirebaseData(path) {
  console.log("fetch data firebase");
  const uri = `${FIREBASE_BASE_URL}${path}.json`;
  try {
    const response = await timeoutError(TIMEOUT_MS, fetch(uri));
    const data = await response.json();
    console.log("fetch data firebase success");
    return data;
  } catch (err) {
    console.error(err);
    console.log("fetch data firebase error");
    if (err.message === PROMISE_TIMEOUT) {
      return await getFirebaseDataFallback(uri);
    }

    throw err;
  }
}

async function getFirebaseDataFallback(uri) {
  console.log("fetch data fallback");
  const response = await fetch(
    `${API_PALLAS_APPS_URL}/firebaseData?uri=${uri}`
  );
  const data = await response.json();
  console.log("fetch data fallback success");
  return data;
}
