import { API_PALLAS_APPS_URL, WHATSAPP_CHAT_URL } from "../ducks/consts";
import toPriceDisplay from "../utils/toPriceDisplay";
import { setSubmitError } from "../ducks/checkoutSlice";
import { fetchAppEssentials } from "../ducks/appStateSlice";
import formatDateTime from "../utils/formatDateTime";

const CheckoutSubmission = {
  sendPallas: async (submittingData, dispatch) => {
    const response = await fetch(`${API_PALLAS_APPS_URL}/pedido`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(submittingData),
    });

    const json = await response.json();
    if (response.status === 422) {
      dispatch(
        fetchAppEssentials(
          submittingData.shopUri,
          submittingData.isInLoco,
          false
        )
      );
      dispatch(setSubmitError(json.error));
      throw new ServerMessageError(json.error);
    } else if (response.status === 424) {
      dispatch(setSubmitError(json.error));
      throw new ServerMessageError(json.error);
    }

    if (!response.ok) {
      throw new Error("No 2XX response, left unhandled.");
    }

    return json;
  },
  sendWhatsApp: (tel, cache) => {
    const costs = cache.costs;
    const checkout = cache.checkout;
    const messageLines = [
      checkout.toDelivery
        ? `*Pedido ${cache.shortReference} Delivery*`
        : `*Pedido ${cache.shortReference} Retirar no balcão*`,
      `Data: ${formatDateTime(cache.createdAt)}`,
      "",
      ...cache.orderedProducts.reduce(
        (ps, p) => [
          ...ps,
          `*${p.name}* ${toPriceDisplay(p.price, ",")}`,
          ...p.detailsList,
          "",
        ],
        []
      ),
      ...(checkout.showSubtotal
        ? [`Subtotal: ${toPriceDisplay(costs.subtotal, ",")}`]
        : []),
      ...(checkout.toDelivery
        ? [`Entrega: ${toPriceDisplay(costs.delivery, ",")}`]
        : []),
      ...(costs.hasValidCoupon
        ? [`Desconto: ${toPriceDisplay(costs.discount, ",")}`]
        : []),
      ...(costs.hasTakeoutDiscount
        ? [
            `Desconto para retirar: ${toPriceDisplay(
              costs.takeoutDiscount,
              ","
            )}`,
          ]
        : []),
      `*Total:* ${toPriceDisplay(costs.total, ",")}`,
      "",
      "*Forma de pagamento*",
      costs.paymentIsCash
        ? `${checkout.payment}: ${toPriceDisplay(costs.paymentValue)}`
        : checkout.payment,
      costs.paymentIsCash && costs.paymentDiff > 0
        ? `Troco: ${toPriceDisplay(costs.paymentDiff)}`
        : "Sem troco",
      "",
      "*Cliente:*",
      checkout.name,
      `Telefone: ${checkout.telephone}`,
      ...(checkout.identification ? [`CPF: ${checkout.identification}`] : []),
      ...(checkout.toDelivery
        ? [
            "",
            "*Endereço de entrega:*",
            `${checkout.deliveryAddress.addressStreet}, ${checkout.deliveryAddress.addressNumber}`,
            checkout.deliveryAddress.complement,
            `${checkout.deliveryAddress.neighborhood} - ${checkout.deliveryAddress.city}/${checkout.deliveryAddress.state}`,
            `CEP ${checkout.deliveryAddress.postalCode}`,
          ]
        : []),
      // "",
      // `Para acompanhar o status do pedido acesse: ${window.location.origin}/${uri}/status/${cache.shortReference}`
    ];
    const message = messageLines.join("\n");
    const encodedMessage = encodeURI(message);
    const croppedEncodedMessage =
      encodedMessage.length > 4096
        ? `${encodedMessage.split(0, 4093)}...`
        : encodedMessage;

    const strippedTel = "55" + tel.toString().replace(rNonNumbers, "");
    return `${WHATSAPP_CHAT_URL}/${strippedTel}?text=${croppedEncodedMessage}`;
  },
};

export default CheckoutSubmission;

class ServerMessageError extends Error {
  constructor(error) {
    super(error.message);
    this.details = error.details;
    this.isUserReadable = true;
  }
}

const rNonNumbers = /\D/g;
