import { useEffect } from "react";

const useShopStyle = (shop) => {
  const loading = shop.name === "";
  const isSelfcheckout = loading ? null : shop.isSelfcheckout;

  const backgroudColor = shop?.backgroudColor || "#dc3545";
  const textColor = shop?.textColor || "#fff";

  useEffect(() => {
    if (loading || isSelfcheckout === null || isSelfcheckout === true) return;
    const css = document.createElement("style");
    css.type = "text/css";
    css.async = false;
    css.innerHTML = `
      #root,
      .fixed-top,
      .fixed-bottom {
        max-width: 760px;
        margin: auto !important;
      }
    `;
    document.body.appendChild(css);
  }, [loading, isSelfcheckout]);

  useEffect(() => {
    if (loading) return;
    const css = document.createElement("style");
    css.type = "text/css";
    css.async = false;
    css.innerHTML = `

      .bg-shop {
        background-color: ${backgroudColor};
      }

      .text-shop {
        color: ${textColor} !important;
      }

      .btn-shop {
        border-color: ${backgroudColor};
        background-color: ${backgroudColor};
        color: ${textColor};
      }
 
      .btn-outline-shop {
          color: ${backgroudColor};
          border-color: ${backgroudColor};
      }

      .btn.btn-link,
      .product-price-color-shop {
        color: ${backgroudColor};
      }

      .border-color-shop {
        border-color: ${backgroudColor};
      }
    `;
    document.body.appendChild(css);
  }, [loading, backgroudColor, textColor]);
};

export default useShopStyle;
