import React from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

export default function NumpadTotem({ onKeyboardChange }) {
  return (
    <Keyboard
      inputName="cpfcnpj"
      theme={"hg-theme-default hg-layout-default totem"}
      onChange={onKeyboardChange}
      layout={{
        default: ["1 2 3", "4 5 6", "7 8 9", "{bksp} 0"],
      }}
      display={{
        "{bksp}": "<",
      }}
      buttonTheme={[
        {
          class: "totem",
          buttons: "1 2 3 4 5 6 7 8 9 {bksp} 0 ",
        },
      ]}
      maxLength={14}
    />
  );
}
