import React from "react";
import { Form, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import CommonHeader from "../containers/CommonHeader";
import { BirthdayInput, PhoneInput } from "../components/NumberInput";
import { validatePhoneNumber } from "../utils/validate";
import { registerUser } from "../services/fidelity";
import { useDispatch, useSelector } from "react-redux";
import { setUserPhone, setUserId } from "../ducks/userSlice";
import useToast from "../hooks/useToast";
import useGoTo from "../hooks/useGoTo";
import SendCodeWarning from "../components/SendCodeWarning";

export default function RegisterScreen() {
  const dispatch = useDispatch();
  const goTo = useGoTo();
  const toast = useToast();
  const { userPhone } = useSelector((state) => state.user);
  const [name, setName] = React.useState("");
  const [birthday, setBirthday] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);
  const [showCodeWaring, setShowCodeWarning] = React.useState(true);
  const [isPolicyChecked, setPolicyChecked] = React.useState(false);

  const onChangePhone = (event) => {
    dispatch(setUserPhone({ phone: event.target.value }));
  };

  const onChangeName = (event) => {
    setName(event.target.value);
  };

  const onChangeBirthday = (event) => {
    setBirthday(event.target.value);
  };

  const onChangePolicy = (event) => {
    setPolicyChecked(event.target.checked);
  };

  const register = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const formatBirthday = birthday.split("-").reverse().join("-");
      const response = await registerUser(name, userPhone, formatBirthday).then(
        (resp) => resp.json()
      );

      if (response.error) {
        throw new Error(response.error?.message ?? "Something wrong happened");
      }

      dispatch(
        setUserId({ id: response.id, sendCodeChannel: response.channel })
      );
      goTo("/confirmation");
    } catch (error) {
      toast.toastError("Erro ao realizar o cadastro!");
    } finally {
      setLoading(false);
    }
  };

  const nameError =
    name.length > 0 && name.length < 4 ? "Nome com no mínimo 4 caracteres" : "";

  const phoneError = validatePhoneNumber(userPhone) ? "" : "Celular inválido";

  const birthdayError =
    birthday && birthday.trim().length < 10 ? "Data inválida" : "";

  const isRegisterDisabled =
    !!nameError || !!phoneError || !!birthdayError || showCodeWaring;

  return (
    <>
      <CommonHeader text="Complete seu cadastro" />
      <div className="mt-3 text-center">
        Só precisamos de mais algumas informações!
      </div>
      <Form className="mt-4 px-3 px-lg-0" onSubmit={register}>
        <TextField
          className="mt-3"
          fullWidth={true}
          variant="outlined"
          label="Celular com DDD"
          value={userPhone}
          onChange={onChangePhone}
          error={!!phoneError}
          helperText={phoneError}
          InputProps={{
            inputComponent: PhoneInput,
          }}
          required
          disabled={isLoading}
        />
        <TextField
          className="mt-3"
          autoFocus
          fullWidth={true}
          value={name}
          onChange={onChangeName}
          error={!!nameError}
          helperText={nameError}
          variant="outlined"
          type="text"
          label="Nome"
          required
          disabled={isLoading}
        />
        <TextField
          className="mt-3"
          fullWidth={true}
          value={birthday}
          onChange={onChangeBirthday}
          error={!!birthdayError}
          helperText={birthdayError}
          variant="outlined"
          label="Data de nascimento"
          InputProps={{
            inputComponent: BirthdayInput,
          }}
          required
          disabled={isLoading}
        />
        <Form.Check
          className="mt-4"
          type="checkbox"
          checked={isPolicyChecked}
          onChange={onChangePolicy}
          label={
            <>
              {`Li e concordo com a `}
              <a
                href="https://sistemapallas.com.br/politica-de-privacidade.html"
                target="_blank"
                rel="noreferrer"
              >
                Política de Privacidade.
              </a>
            </>
          }
        />
        <div className="p-3 fixed-bottom">
          <SendCodeWarning
            showWarning={showCodeWaring}
            setShowWarning={setShowCodeWarning}
          />
          <Button
            className="w-100 mt-3"
            variant="danger"
            type="submit"
            disabled={isRegisterDisabled || isLoading || !isPolicyChecked}
          >
            {isLoading ? "Enviando..." : "Cadastre-se"}
          </Button>
        </div>
      </Form>
    </>
  );
}
