export default function qttDisplay(qtt, isKilogram) {
  if (isKilogram) {
    if (qtt < 1) {
      return `${qtt * 1000} g`;
    }

    return `${qtt} Kg`;
  }

  return qtt.toString();
}
