import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Wizard, Steps, Step } from "react-albus";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import qttDisplay from "../utils/qttDisplay";
import QuestionsCardTotem from "./QuestionsCardTotem";

export default function FloatingProductOrderFooterTotem({
  isSelfcheckoutTotem,

  decrement,
  increment,
  qtt,
  qttMinimum,
  isKilogram,
  cancel,
  addToCart,
  disabled,
  buttonLabel,

  showWizard,
  setShowWizard,
  orderUuid,
  questions,
  answers,
}) {
  return (
    <Container
      className={`d-flex align-items-center pt-3 pb-3 bg-white ${
        isSelfcheckoutTotem ? "mt-5" : "fixed-bottom"
      }`}
    >
      <Button
        variant="link"
        onClick={decrement}
        className="flex-shrink-0 totem-product-button-qtt"
        disabled={qtt <= qttMinimum}
      >
        <RemoveIcon />
      </Button>
      <span className="flex-shrink-0 user-select-none totem-product-button-add">
        {qttDisplay(qtt, isKilogram)}
      </span>
      <Button
        variant="link"
        onClick={increment}
        className="flex-shrink-0 totem-product-button-qtt"
      >
        <AddIcon />
      </Button>
      <Button
        variant="shop"
        className="d-inline-flex flex-grow-1 p-3 justify-content-center totem-product-button-add"
        onClick={addToCart}
        disabled={disabled}
      >
        {buttonLabel}
      </Button>
      <QuestionsWizard
        showWizard={showWizard}
        setShowWizard={setShowWizard}
        orderUuid={orderUuid}
        cancel={cancel}
        addToCart={addToCart}
        questions={questions}
        answers={answers}
      />
    </Container>
  );
}

function QuestionsWizard({
  showWizard,
  setShowWizard,
  orderUuid,
  cancel,
  addToCart,
  questions,
  answers,
}) {
  const handleClose = () => setShowWizard(false);

  return (
    <Modal
      backdrop="static"
      fullscreen={true}
      dialogClassName="product-questions-dialog-body "
      show={showWizard}
      onHide={handleClose}
    >
      <Wizard>
        <Steps>
          {questions.map((step, index) => (
            <Step
              key={step.id}
              id={`${step.id}`}
              render={({ next, previous }) => (
                <QuestionsCardTotem
                  question={step}
                  orderUuid={orderUuid}
                  isAvailableToOrder={true}
                  actions={
                    <StepsButton
                      question={step}
                      numberOfSteps={questions.length}
                      currentStep={index}
                      next={next}
                      previous={previous}
                      cancel={cancel}
                      addToCart={addToCart}
                      answers={answers}
                    />
                  }
                />
              )}
            />
          ))}
        </Steps>
      </Wizard>
    </Modal>
  );
}

function StepsButton({
  numberOfSteps,
  currentStep,
  question,
  next,
  previous,
  cancel,
  addToCart,
  answers,
}) {
  const answersQtt = answers
    .filter((a) => a.questionId === question.id)
    .reduce((acc, a) => acc + a.qtt, 0);
  const disabled = question.minQtt > 0 && answersQtt < question.minQtt;
  const first = currentStep === 0;
  const last = currentStep === numberOfSteps - 1;

  return (
    <>
      <Button
        variant="outline-dark"
        className="p-3 totem-product-button-add"
        onClick={first ? cancel : previous}
      >
        Voltar
      </Button>
      <Button
        variant="shop"
        className="p-3 totem-product-button-add"
        onClick={last ? addToCart : next}
        disabled={disabled}
      >
        {last ? "Finalizar" : "Continuar"}
      </Button>
    </>
  );
}
