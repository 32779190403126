import React from "react";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash.get";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Figure from "react-bootstrap/Figure";
import WarningIcon from "@material-ui/icons/Warning";
import DoneIcon from "@material-ui/icons/Done";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import toPriceDisplay from "../utils/toPriceDisplay";
import addLineBreaks from "../utils/addLineBreaks";
import {
  answerSingleChoiceQuestion,
  answerManyChoicesQuestion,
} from "../ducks/shoppingCartSlice";
import FormCheck from "react-bootstrap/FormCheck";
import ScrollButton from "../components/ScrollButton";

export default function QuestionsCardTotem({
  isAvailableToOrder,
  question,
  orderUuid,
  actions,
}) {
  const { description, minQtt, maxQtt } = question;
  const answers =
    useSelector((state) => state.shoppingCart.answersByOrder[orderUuid]) || [];
  const questionAnswers = answers.filter((a) => a.questionId === question.id);
  const qtt = questionAnswers.reduce((acc, a) => acc + a.qtt, 0);

  const isSingleChoice = maxQtt === 1;
  const isCombo = question.options.reduce((acc, o) => acc && o.comboItem, true);

  const getHelpText = () => {
    if (isCombo) {
      return "";
    } else if (minQtt === maxQtt) {
      return `Escolha ${minQtt}`;
    } else if (minQtt > 0) {
      return `Escolha ${minQtt}, até ${maxQtt}`;
    } else {
      return `Escolha até ${maxQtt}`;
    }
  };

  return (
    <Card className="checkout-dialog-body">
      <ScrollButton elementId="totem-question-options" bottom={120} />
      <QuestionTitle
        description={description}
        helpText={getHelpText()}
        minQtt={minQtt}
        qtt={qtt}
      />
      {question.options.length > 0 && (
        <OptionsList
          isAvailableToOrder={isAvailableToOrder}
          question={question}
          orderUuid={orderUuid}
          maxQtt={maxQtt}
          isSingleChoice={isSingleChoice}
          answers={questionAnswers}
        />
      )}
      <Card.Footer className="d-flex flex-row align-items-center justify-content-between bg-white mt-auto">
        {actions}
      </Card.Footer>
    </Card>
  );
}

const QuestionTitle = ({ description, helpText, minQtt, qtt }) => {
  return (
    <Card.Title
      as="h3"
      className="d-flex flex-column justify-content-center bg-light m-0 pt-3 pb-5"
    >
      <span className="totem-product-name text-center">
        {description}
        <br />
        <small className="text-muted">{helpText}</small>
      </span>

      {minQtt > 0 && qtt < minQtt && (
        <div className="d-flex flex-column align-items-center mt-3">
          {minQtt > 1 && (
            <Badge className="p-2 mr-1" variant="danger">
              {`${qtt}/${minQtt}`}
            </Badge>
          )}
          <Badge className="p-2" variant="danger">
            OBRIGATÓRIO <WarningIcon />
          </Badge>
        </div>
      )}

      {minQtt > 0 && qtt >= minQtt && (
        <div className="d-flex flex-column align-items-center mt-3">
          {minQtt > 1 && (
            <Badge className="p-2 mr-1" variant="success">
              {`${qtt}/${minQtt}`}
            </Badge>
          )}
          <Badge className="p-2" variant="success">
            OBRIGATÓRIO <DoneIcon />
          </Badge>
        </div>
      )}
    </Card.Title>
  );
};

const OptionsList = (props) => {
  const {
    isAvailableToOrder,
    question,
    orderUuid,
    maxQtt,
    isSingleChoice,
    answers,
  } = props;

  const { id: questionId, isAllowedOptionRepetition } = question;

  const dispatch = useDispatch();

  const statefulOptions = question.options.map((option) => {
    const answer = answers.find((a) => a.optionId === option.id && a.qtt > 0);
    const answeredQtt = get(answer, "qtt", 0);
    const isChecked = answeredQtt === 1;
    return {
      ...option,
      answeredQtt,
      isChecked,
      onRadioClick: () =>
        dispatch(
          answerSingleChoiceQuestion({
            questionId,
            orderUuid,
            optionId: option.id,
            isChecked: !isChecked,
            question,
          })
        ),
      onCheckboxClick: () =>
        dispatch(
          answerManyChoicesQuestion({
            questionId,
            orderUuid,
            optionId: option.id,
            qtt: isChecked ? 0 : 1,
            question,
          })
        ),
      onIncrementClick: () =>
        dispatch(
          answerManyChoicesQuestion({
            questionId,
            orderUuid,
            optionId: option.id,
            qtt: answeredQtt + 1,
            question,
          })
        ),
      onDecrementClick: () =>
        dispatch(
          answerManyChoicesQuestion({
            questionId,
            orderUuid,
            optionId: option.id,
            qtt: answeredQtt - 1,
            question,
          })
        ),
    };
  });

  const questionTotalAnsweredQtt = answers.reduce((acc, a) => acc + a.qtt, 0);
  const isIncrementDisabled = questionTotalAnsweredQtt >= maxQtt;

  if (window.screen.width < 1024) {
    return (
      <div
        id="totem-question-options"
        className="totem-question-options-column"
      >
        <ListGroup>
          {statefulOptions.map((option) => (
            <ListGroup.Item className="mb-4 border-none" key={option.id}>
              {option.comboItem ? (
                <OptionComboItem questionId={questionId} option={option} />
              ) : (
                <Option
                  questionId={questionId}
                  isAllowedOptionRepetition={isAllowedOptionRepetition}
                  isAvailableToOrder={isAvailableToOrder}
                  isSingleChoice={isSingleChoice}
                  isIncrementDisabled={isIncrementDisabled}
                  option={option}
                />
              )}
            </ListGroup.Item>
          ))}
          <ListGroup.Item className="my-5 border-none"></ListGroup.Item>
        </ListGroup>
      </div>
    );
  }

  return (
    <div
      id="totem-question-options"
      className="totem-question-options-column p-0"
    >
      {splitOptionsInRows(statefulOptions, 2).map((optiondInRow) => (
        <Row key={makeOptionsRowKey(optiondInRow)} className="mx-2 my-3">
          {optiondInRow.map((option, index) =>
            option.comboItem ? (
              <OptionComboItem questionId={questionId} option={option} />
            ) : (
              <Option
                className="mx-3"
                questionId={questionId}
                isAllowedOptionRepetition={isAllowedOptionRepetition}
                isAvailableToOrder={isAvailableToOrder}
                isSingleChoice={isSingleChoice}
                isIncrementDisabled={isIncrementDisabled}
                option={option}
              />
            )
          )}
          {optiondInRow.length === 1 && <Col className="mx-3"></Col>}
        </Row>
      ))}
      <Row className="my-5"></Row>
    </div>
  );
};

const Option = ({
  questionId,
  isAllowedOptionRepetition,
  isAvailableToOrder,
  isSingleChoice,
  isIncrementDisabled,
  option,
  className,
}) => {
  const radioClick =
    isAvailableToOrder &&
    !option.disabled &&
    !isAllowedOptionRepetition &&
    isSingleChoice;

  const checkBoxClick =
    isAvailableToOrder &&
    !option.disabled &&
    !isAllowedOptionRepetition &&
    !isSingleChoice &&
    (!isIncrementDisabled || option.isChecked);

  const plusOneClick =
    isAvailableToOrder &&
    !option.disabled &&
    !isIncrementDisabled &&
    isAllowedOptionRepetition &&
    option.answeredQtt === 0;

  const handleClick = radioClick
    ? option.onRadioClick
    : checkBoxClick
    ? option.onCheckboxClick
    : plusOneClick
    ? option.onIncrementClick
    : null;

  const isSelected = option.answeredQtt > 0;
  return (
    <Col
      id={`col-question-${questionId}-option-${option.id}`}
      className={`totem-option-item py-3 ${className} ${
        isSelected ? "border-color-shop" : ""
      }`}
      onClick={handleClick}
    >
      <Row>
        <Col xs={option.pictureUrl ? 6 : 12} className="totem-product-details">
          <span className="font-weight-bold">{option.label}</span>
          {option.details && (
            <p className="mb-0 text-muted">
              <small>{addLineBreaks(option.details)}</small>
            </p>
          )}
          {option.disabled && (
            <>
              <br />
              <Badge variant="secondary">
                <small>INDISPONÍVEL</small>
              </Badge>
            </>
          )}
          {!option.disabled && !!parseFloat(option.price) && (
            <p className="font-weight-bold m-0 mt-2">
              <span>+ {toPriceDisplay(option.price)}</span>
            </p>
          )}
        </Col>
        {option.pictureUrl && (
          <Col
            xs="6"
            className="d-flex flex-column justify-content-center pl-0"
          >
            <Figure.Image
              src={option.pictureUrl}
              alt="Ilustração da opção"
              fluid={false}
              className="w-100 m-0 p-0 totem-option-image"
              rounded
            />
          </Col>
        )}
      </Row>
      {isAvailableToOrder &&
        !option.disabled &&
        isAllowedOptionRepetition &&
        option.answeredQtt > 0 && (
          <Row className="justify-content-center pt-2">
            <Button
              variant="link"
              className={"totem-question-button-qtt"}
              onClick={option.onDecrementClick}
              style={{
                visibility: option.answeredQtt > 0 ? "visible" : "hidden",
              }}
            >
              <RemoveIcon />
            </Button>
            {option.answeredQtt > 0 && (
              <span className="totem-product-button-add my-auto">
                {option.answeredQtt}
              </span>
            )}
            <Button
              variant="link"
              className={"totem-question-button-qtt"}
              onClick={option.onIncrementClick}
              disabled={isIncrementDisabled}
            >
              <AddIcon />
            </Button>
          </Row>
        )}
    </Col>
  );
};

const OptionComboItem = ({ questionId, option }) => {
  return (
    <ListGroup.Item key={option.id} className="m-0 p-0">
      <FormCheck
        id={`question-${questionId}-option-${option.id}`}
        className="d-flex flex-row-reverse align-items-center"
      >
        <Form.Label className="w-100 p-0 m-0">
          <Row>
            {option.pictureUrl && (
              <Col
                xs="4"
                className="d-flex flex-column justify-content-center p-0"
              >
                <Figure.Image
                  src={option.pictureUrl}
                  alt="Ilustração da opção"
                  fluid={false}
                  className="w-100 my-4"
                  rounded
                />
              </Col>
            )}
            <Col xs={option.pictureUrl ? 8 : 12} className="pt-3 pb-3">
              <span>{option.label}</span>
              {option.details && (
                <p className="mb-0 text-muted">
                  <small>{addLineBreaks(option.details)}</small>
                </p>
              )}
            </Col>
          </Row>
        </Form.Label>
      </FormCheck>
    </ListGroup.Item>
  );
};

function splitOptionsInRows(options, rowSize) {
  return options.reduce((rows, product, index) => {
    const isNewPage = index % rowSize === 0;
    if (isNewPage) {
      return [...rows, [product]];
    }

    const tailRow = rows[rows.length - 1];
    const otherRows = rows.slice(0, rows.length - 1);
    return [...otherRows, [...tailRow, product]];
  }, []);
}

function makeOptionsRowKey(options) {
  return options.map((o) => `option(${o.id})`).join("+");
}
