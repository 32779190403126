import { createSlice } from "@reduxjs/toolkit";

const initialState = Object.freeze({
  userId: 0,
  userUuid: "",
  userPhone: "",
  userName: "",
  userBirthday: "",
  sendCodeChannel: "",
  sendCodeTimeStamp: 0,
  fidelity: {},
  validOrders: [],
});

export const shopSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logoff: () => {
      return initialState;
    },
    setUserId: (state, { payload }) => {
      state.userId = payload.id;
      state.sendCodeChannel = payload.sendCodeChannel;
      state.sendCodeTimeStamp = Date.now();
    },
    setConfirmation: (state, { payload }) => {
      state.userUuid = payload.uuid;
      state.userPhone = payload.telefone;
      state.userName = payload.nome;
      state.userBirthday = payload.nascido_em;
    },
    setUserPhone: (state, { payload }) => {
      state.userPhone = payload.phone;
    },
    setSendCodeTimeStamp: (state, { payload }) => {
      state.sendCodeChannel = payload.sendCodeChannel;
      state.sendCodeTimeStamp = Date.now();
    },
    setValidOrders: (state, { payload }) => {
      state.validOrders = payload.validOrders;
    },
  },
});

export const {
  logoff,
  setUserId,
  setConfirmation,
  setUserPhone,
  setSendCodeTimeStamp,
  setValidOrders,
} = shopSlice.actions;

export default shopSlice.reducer;
