import React from "react";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import toPriceDisplay from "../utils/toPriceDisplay";
import { selectStatefulCosts } from "../ducks/checkoutSlice";
import UnavailableDeliveryWarning from "../components/UnavailableDeliveryWarning";

const DeliveryLine = ({
  isSearchingLocation,
  deliveryError,
  deliveryPrice,
  searchedDelivery,
}) => {
  if (isSearchingLocation) {
    return (
      <p className="d-flex justify-content-between text-muted">
        <span>Entrega</span>
        <Spinner as="span" animation="border" size="sm" />
        <MotorcycleIcon fontSize="small" />
      </p>
    );
  } else if (deliveryError) {
    return (
      <p className="d-flex justify-content-between text-muted">
        <span className="text-danger">
          <small>{deliveryError}</small>
        </span>
        <span className="text-danger">
          <MotorcycleIcon fontSize="small" />
        </span>
      </p>
    );
  } else {
    return (
      <p className="d-flex justify-content-between text-muted">
        <span>
          Entrega
          {searchedDelivery.fee.estimatedMinutes
            ? ` (${searchedDelivery.fee.estimatedMinutes} min)`
            : ""}
        </span>
        <span className="text-muted">
          <MotorcycleIcon fontSize="small" className="mr-2" />
          {toPriceDisplay(deliveryPrice)}
        </span>
      </p>
    );
  }
};

const CouponLine = ({ couponError, isSearchingCoupon, discount }) => {
  if (isSearchingCoupon) {
    return (
      <p className="d-flex justify-content-between text-muted">
        <span>Cupom</span>
        <Spinner as="span" animation="border" size="sm" />
        <LocalOfferIcon fontSize="small" />
      </p>
    );
  } else if (couponError) {
    return (
      <p className="d-flex justify-content-between text-muted">
        <span className="text-danger">
          <small>{couponError}</small>
        </span>
        <span className="text-danger">
          <LocalOfferIcon fontSize="small" />
        </span>
      </p>
    );
  } else {
    return (
      <p className="d-flex justify-content-between text-muted">
        <span>Cupom</span>
        <span className="text-success">
          <LocalOfferIcon fontSize="small" className="mr-2" />-
          {toPriceDisplay(discount)}
        </span>
      </p>
    );
  }
};

export default function CheckoutCosts() {
  const {
    searchedDelivery,
    toDelivery,
    isSearchingLocation,
    isSearchingCoupon,
    coupon,
  } = useSelector((state) => state.checkout);
  const costs = useSelector(selectStatefulCosts);

  return (
    <div className="p-3">
      {costs.showSubtotal && (
        <p className="d-flex justify-content-between text-muted">
          <span>Subtotal</span>
          <span>{toPriceDisplay(costs.subtotal)}</span>
        </p>
      )}

      {toDelivery && (
        <DeliveryLine
          isSearchingLocation={isSearchingLocation}
          deliveryError={costs.deliveryError}
          deliveryPrice={costs.delivery}
          searchedDelivery={searchedDelivery}
        />
      )}

      {coupon && (
        <CouponLine
          isSearchingCoupon={isSearchingCoupon}
          couponError={costs.couponError}
          discount={costs.discount}
        />
      )}

      {costs.hasTakeoutDiscount && (
        <p className="d-flex justify-content-between text-muted">
          <span>Desconto para retirar</span>
          <span className="text-success">
            <LocalOfferIcon fontSize="small" className="mr-2" />-
            {toPriceDisplay(costs.takeoutDiscount)}
          </span>
        </p>
      )}

      {costs.fidelityDiscount && (
        <p className="d-flex justify-content-between text-muted">
          <span>Desconto fidelidade</span>
          <span className="text-success">
            <LocalOfferIcon fontSize="small" className="mr-2" />-
            {toPriceDisplay(costs.fidelityDiscount)}
          </span>
        </p>
      )}

      <p className="d-flex justify-content-between font-weight-bold">
        <span>Total</span>
        <span>{toPriceDisplay(costs.total)}</span>
      </p>

      <UnavailableDeliveryWarning />
    </div>
  );
}
