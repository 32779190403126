import Bugsnag from '@bugsnag/js'

Bugsnag.start({
  apiKey: 'a32e9c2020ed2c61ed0448571243fc66',
  onError: (event) => {
    // attaches evidence about what bundles are running
    event.addMetadata('app', {
      bundles: Array.from(document.querySelectorAll('script')).map(it => it.src),
    });

    // snapshots the current UI state
    event.addMetadata('store', window.store && window.store.getState());
  },
});

/**
 * Notify our error tracker (currently Busgnag).
 *
 * @param {Error} payload `Error` instance with the stack trace.
 */
export async function notifyErrorTracker(error) {
  Bugsnag.notify(error);
}
