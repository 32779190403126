import React from "react";
import Button from "react-bootstrap/Button";
import CheckIcon from "@material-ui/icons/Check";
import { useDispatch, useSelector } from "react-redux";
import { setInLocoAnswer } from "../ducks/checkoutSlice";

export default function InLocoForm() {
  const dispatch = useDispatch();
  const { isTakingHome } = useSelector((state) => state.checkout);

  const setTakingHome = (value) => {
    dispatch(
      setInLocoAnswer({
        name: "isTakingHome",
        value,
      })
    );
  };

  return (
    <div className="p-3">
      <div className="d-flex justify-content-between">
        <Button
          className="w-100 p-2"
          variant={!isTakingHome ? "danger" : "outline-danger"}
          onClick={() => setTakingHome(false)}
        >
          {!isTakingHome && <CheckIcon className="mr-2" />}
          Vou comer aqui
        </Button>
        <div className="m-2" />
        <Button
          className="w-100 p-2"
          variant={isTakingHome ? "danger" : "outline-danger"}
          onClick={() => setTakingHome(true)}
        >
          {isTakingHome && <CheckIcon className="mr-2" />}
          Embalar para viagem
        </Button>
      </div>
    </div>
  );
}
