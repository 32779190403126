import { API_PALLAS_APPS_URL } from '../ducks/consts';

const RemoteOrder = {
  retrieveOrder: async (shopId, shortReference) => {
    const response = await fetch(`${API_PALLAS_APPS_URL}/pedido/detalhes`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        lojaId: shopId,
        shortReference
      })
    });
    if (!response.ok) {
      throw new Error('No 2XX response, left unhandled.');
    }

    const json = await response.json();
    if (!json || typeof(json) !== 'object') {
      throw new Error('Unknown JSON response.');
    }

    return json;
  },
  retrieveStatus: async (orderId) => {
    const response = await fetch(`${API_PALLAS_APPS_URL}/pedido/${orderId}/status`);
    if (!response.ok) {
      throw new Error('No 2XX response, left unhandled.');
    }

    const json = await response.json();
    if (!json || !Array.isArray(json)) {
      throw new Error('Unknown JSON response.');
    }

    return json;
  },
};

export default RemoteOrder;
