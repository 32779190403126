import { createSlice } from "@reduxjs/toolkit";
import assignPick from "../utils/assignPick";
import { STATE_STEP } from "./consts";
import { parseProductQuestions } from "../utils/firebase-adapters";
import getFirebaseData from "../utils/firebase-get-data";

const initialState = Object.freeze({
  activeGroupId: 0,
  groups: [],
  items: [],
  questionsByProduct: {},
  questionsStateByProduct: {},
  questionsCached: {},
});

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setActiveGroupId: (state, { payload }) => {
      state.activeGroupId = payload.activeGroupId;
    },
    setGroupedProductsWithQuestions: (state, { payload }) => {
      assignPick(state, payload, ["groups", "items"]);
      payload.items.forEach((product) => {
        state.questionsByProduct[product.id] = [];
        state.questionsStateByProduct[product.id] = STATE_STEP.INITIAL;
      });
    },
    setLoading: (state, { payload }) => {
      state.questionsStateByProduct[payload.productId] = STATE_STEP.LOADING;
    },
    setReady: (state, { payload }) => {
      state.questionsCached = {
        ...state.questionsCached,
        ...payload.questions.reduce((hash, q) => ({ ...hash, [q.id]: q }), {}),
      };
      state.questionsByProduct[payload.productId] = payload.questions;
      state.questionsStateByProduct[payload.productId] = STATE_STEP.READY;
    },
    setError: (state, { payload }) => {
      state.questionsStateByProduct[payload.productId] = STATE_STEP.ERROR;
    },
  },
});

export const { setActiveGroupId } = productsSlice.actions;

export const { setGroupedProductsWithQuestions } = productsSlice.actions;

export const requireProducts = (productId) => async (dispatch, getState) => {
  const {
    products: { questionsStateByProduct, items, questionsCached },
    appState: { namespace },
  } = getState();

  const product = items.find((p) => p.id === productId);
  if (!product) {
    return;
  }

  const stateStep = questionsStateByProduct[productId];
  if (stateStep === STATE_STEP.LOADING || stateStep === STATE_STEP.READY) {
    return;
  }

  const questionsIds = product.questionsIds || [];
  const questionsIdsCached = questionsIds.filter((qid) =>
    questionsCached.hasOwnProperty(qid)
  );
  const questionsIdsToFetch = questionsIds.filter(
    (qid) => !questionsCached.hasOwnProperty(qid)
  );

  const questionsCachedArray = questionsIdsCached.map(
    (qid) => questionsCached[qid]
  );
  if (questionsIdsToFetch.length === 0) {
    dispatch(
      productsSlice.actions.setReady({
        productId,
        questions: questionsCachedArray,
      })
    );
    return;
  }

  dispatch(productsSlice.actions.setLoading({ productId }));
  await Promise.all(
    questionsIdsToFetch.map((questionId) =>
      getFirebaseData(`/${namespace}/perguntas/${questionId}`)
    )
  )
    .then(parseProductQuestions)
    .then((questions) => {
      const allProductQuestions = [...questions, ...questionsCachedArray];
      dispatch(
        productsSlice.actions.setReady({
          productId,
          questions: allProductQuestions,
        })
      );
    })
    .catch(() => dispatch(productsSlice.actions.setError({ productId })));
};

export default productsSlice.reducer;
