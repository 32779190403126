import React from "react";
import { Accordion, Button, Card } from "react-bootstrap";

export default function GroupCard({ group, onClick }) {
  const color = group.image ? "rgba(255,255,255,0)" : "#dc3545";

  return (
    <Card key={group.id} className="border-0 mb-3">
      <Card.Header
        className="btn-image border-0 p-0"
        style={{
          "background-image": `linear-gradient(50deg, rgba(0,0,0,1) 0%, ${color} 55%), url("${group.image}")`,
        }}
      >
        <Accordion.Toggle
          className={`w-100 text-left pa-4 text-white btn-image-label`}
          as={Button}
          variant="link"
          eventKey={`${group.id}`}
          onClick={onClick}
        >
          <h2 className="font-weight-bold mt-4 mb-4">{group.name}</h2>
        </Accordion.Toggle>
      </Card.Header>
    </Card>
  );
}
