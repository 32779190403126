import React from "react";
import { useSelector } from "react-redux";
import Badge from "react-bootstrap/Badge";
import { selectStatefulCosts } from "../ducks/checkoutSlice";
import toPriceDisplay from "../utils/toPriceDisplay";
import UnavailableDeliveryWarning from "./UnavailableDeliveryWarning";

export const ProductDivider = () => (
  <hr
    style={{
      borderTop: "2px solid #e6e6e6",
      margin: 0,
    }}
  />
);

export default function ProductsListCart(props) {
  const costs = useSelector(selectStatefulCosts);
  const { products, renderOptions = null } = props;

  return (
    <div className="p-3">
      {products.map((product) => {
        const errorTextColor =
          product.error.isDisabled || product.error.isPriceChanged
            ? "text-danger"
            : null;
        return (
          <div className={`pb-3 ${errorTextColor}`} key={product.uuid}>
            <div className="pb-3 d-flex flex-row justify-content-between align-items-center">
              <div>
                {product.name}
                {product.error.isDisabled && (
                  <Badge className="ml-1" variant="secondary">
                    <small>INDISPONÍVEL</small>
                  </Badge>
                )}

                {product.detailsList && product.detailsList.length > 0 && (
                  <ul className="mb-0">
                    {product.detailsList.map((detail, index) => (
                      <li key={index}>
                        <small
                          className={
                            errorTextColor ? errorTextColor : "text-muted"
                          }
                        >
                          {detail.name}
                        </small>
                        {detail.error.isDisabled && (
                          <Badge className="ml-1" variant="secondary">
                            <small>INDISPONÍVEL</small>
                          </Badge>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div>
                <div className="d-flex flex-row align-items-center">
                  <p className="m-0">{toPriceDisplay(product.price)}</p>
                  {renderOptions(product)}
                </div>
                {product.error.isPriceChanged && (
                  <Badge className="ml-1" variant="secondary">
                    <small>{`${
                      product.error.priceDiff.gt(0) ? "+" : "-"
                    } ${toPriceDisplay(product.error.priceDiff.abs())}`}</small>
                  </Badge>
                )}
              </div>
            </div>
            <ProductDivider />
          </div>
        );
      })}
      <div>
        <p className="m-0 d-flex justify-content-between font-weight-bold">
          <span>Subtotal</span>
          <span>{toPriceDisplay(costs.subtotal)}</span>
        </p>
      </div>
      <UnavailableDeliveryWarning />
    </div>
  );
}
