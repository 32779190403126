import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { useSelector } from "react-redux";
import {
  ProductName,
  ProductPrice,
  ProductBadges,
} from "../components/ProductsList";
import useGoTo from "../hooks/useGoTo";
import { getProductAvailability } from "../hooks/useWorkingTime";

export default function ProductsHighlights() {
  const goTo = useGoTo();
  const highlightProducts = useSelector((state) =>
    state.products.items.filter((it) => it.isHighlight)
  );
  const availableProducts = highlightProducts.filter(
    (p) => getProductAvailability(p).isAvailable
  );

  if (!availableProducts.length) {
    return null;
  }

  const handleClickFn = (productId) => () => {
    goTo(`/product/${productId}`);
  };

  return (
    <Container className="products-highlights">
      <h2 className="font-weight-bold mb-2">Destaques</h2>
      <div className="d-flex flex-row products-highlights-content pb-4">
        {availableProducts.map((product) => (
          <ProductShortCard
            key={product.id}
            product={product}
            onClick={handleClickFn(product.id)}
          />
        ))}
        <div className="div-empty" />
      </div>
    </Container>
  );
}

function ProductShortCard({ product, onClick }) {
  return (
    <Card className="product-short-card mr-3 cursor-pointer" onClick={onClick}>
      {!!product.pictureUrl && (
        <Card.Img
          src={product.pictureUrl}
          alt="Ilustração do produto"
          height={90}
          variant="top"
          className="image-fit-cover"
        />
      )}
      <Card.Body className="d-flex flex-column justify-content-between">
        <ProductName product={product} />
        <ProductBadges product={product} />
        <ProductPrice product={product} />
      </Card.Body>
    </Card>
  );
}
