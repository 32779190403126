import React from "react";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import useWorkingTime from "../hooks/useWorkingTime";
import WorkingTime from "./WorkingTime";

export default function ShopHeader() {
  const shop = useSelector((state) => state.shop);
  const { currentWeekDay, rulesDelivery, rulesToGo, rulesInloco } =
    useWorkingTime(shop);
  const [isShowingMore, setShowingMore] = React.useState(false);

  return (
    <Container as="header" className="shop-header mb-3">
      <Button
        size="sm"
        variant="link"
        className="w-100 text-center mt-5"
        onClick={() => setShowingMore(!isShowingMore)}
      >
        {isShowingMore ? (
          <>
            <ExpandLessIcon /> Ocultar dados da loja
          </>
        ) : (
          <>
            <ExpandMoreIcon /> Ver dados da loja
          </>
        )}
      </Button>
      {isShowingMore && (
        <Row>
          <Card className="w-100">
            <Card.Body>
              <Card.Subtitle as="h3" className="pt-3 text-muted">
                Contato
              </Card.Subtitle>
              <p>{`Telefone: ${shop.phone}`}</p>
              <p>{`WhatsApp: ${shop.whatsapp}`}</p>
              <Card.Subtitle as="h3" className="pt-3 text-muted">
                Endereço
              </Card.Subtitle>
              <Card.Text as="address">
                {shop.addressStreet}, {shop.addressNumber}
                {shop.complement ? ", " + shop.complement : ""}
                <br />
                <small className="text-muted">
                  {shop.neighborhood}, {shop.city}/{shop.state} - CEP{" "}
                  {shop.postalCode}
                </small>
              </Card.Text>
              <WorkingTime
                label="Horário de funcionamento para entrega"
                rules={rulesDelivery}
                currentWeekDay={currentWeekDay}
              />
              <WorkingTime
                label="Horário de funcionamento para retirar"
                rules={rulesToGo}
                currentWeekDay={currentWeekDay}
              />
              <WorkingTime
                label="Horário de funcionamento na mesa"
                rules={rulesInloco}
                currentWeekDay={currentWeekDay}
              />
            </Card.Body>
          </Card>
        </Row>
      )}
    </Container>
  );
}
