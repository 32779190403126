import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import {
  setFormAnswer,
  setPayment,
  selectCard,
  removeCard,
  PIX_OPTION,
  MERCADO_PAGO_OPTION,
  POS_PRESENTIAL_OPTION,
  clearPayment,
} from "../ducks/checkoutSlice";
import PaymentCardImage from "../components/PaymentCardImage";
import pixURL from "../assets/payment-flat-rounded/pix.png";
import mpURL from "../assets/payment-flat-rounded/mercado-pago.svg";
import posURL from "../assets/payment-flat-rounded/pos-stone.jpg";
import CardMercadoPago from "./CardMercadoPago";
import CommonHeader from "./CommonHeader";

export default function PaymentSelection() {
  const dispatch = useDispatch();
  const [cardToDelete, setCardToDelete] = React.useState(null);
  const {
    payment,
    cardSelected,
    isPaymentListVisible,
    isCardFormVisible,
    cards,
  } = useSelector((state) => state.checkout);

  const {
    mercadoPagoLogin,
    mercadoPagoCartao,
    mercadoPagoPix,
    presentialPosPayment,
  } = useSelector((state) => state.shop);

  useEffect(() => {
    if (payment) {
      if (
        !(mercadoPagoPix && PIX_OPTION.name === payment) &&
        !(mercadoPagoLogin && MERCADO_PAGO_OPTION.name === payment) &&
        !(mercadoPagoCartao && cardSelected) &&
        !(presentialPosPayment && POS_PRESENTIAL_OPTION.name === payment)
      ) {
        dispatch(clearPayment());
      }
    }
  }, [
    mercadoPagoLogin,
    mercadoPagoCartao,
    mercadoPagoPix,
    payment,
    cardSelected,
    presentialPosPayment,
    dispatch,
  ]);

  const onToChangeClick = () => {
    dispatch(setFormAnswer({ name: "isPaymentListVisible", value: true }));
  };

  const onPaymentSelect = (selected) => {
    dispatch(setPayment(selected));
  };

  const cancelSelection = () => {
    dispatch(setFormAnswer({ name: "isPaymentListVisible", value: false }));
    return false;
  };

  const cancelCardForm = () => {
    dispatch(setFormAnswer({ name: "isCardFormVisible", value: false }));
  };

  const onCardSelection = (card) => {
    dispatch(selectCard(card));
  };

  const onRemoveCard = () => {
    dispatch(removeCard(cardToDelete));
    setCardToDelete(null);
  };

  const addNewCard = () => {
    dispatch(setFormAnswer({ name: "isPaymentListVisible", value: false }));
    dispatch(setFormAnswer({ name: "isCardFormVisible", value: true }));
  };

  const showDeleteCard = (event, card) => {
    event.stopPropagation();
    setCardToDelete(card);
  };

  const cancelDeleteCard = () => {
    setCardToDelete(null);
  };

  return (
    <div className="p-3">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-column">
          <p className="m-0 font-weight-bold">Forma de pagamento</p>
          {payment && (
            <p className="mt-1 mb-0 text-muted">
              <small>{payment}</small>
            </p>
          )}
        </div>
        <div>
          {payment && (
            <PaymentCardImage paymentLabel={payment} className="mr-2" />
          )}
          <Button variant="link" onClick={onToChangeClick}>
            {payment ? "Trocar" : "Escolher"}
          </Button>
        </div>
      </div>

      <Modal
        show={isPaymentListVisible && !cardToDelete}
        onHide={cancelSelection}
      >
        <CommonHeader text="Como deseja pagar?" onBack={cancelSelection} />
        <Modal.Body className="pt-0">
          <ListGroup style={{ minHeight: "300px" }}>
            {presentialPosPayment && (
              <ListGroup.Item
                href="#"
                key="presentialPosPayment"
                onClick={() => onPaymentSelect(POS_PRESENTIAL_OPTION)}
                className="mt-3 d-flex flex-row align-items-center justify-content-between cursor-pointer"
              >
                <img
                  src={posURL}
                  alt="maquininha"
                  height="80"
                  className="pt-1 pb-1"
                />
                <div className="text-center">
                  Maquininha
                  <br /> de cartão
                </div>
              </ListGroup.Item>
            )}
            {mercadoPagoPix && (
              <ListGroup.Item
                href="#"
                key="pix"
                onClick={() => onPaymentSelect(PIX_OPTION)}
                className="mt-3 d-flex flex-row align-items-center justify-content-between cursor-pointer"
              >
                <img src={pixURL} alt="PIX" height="65" className="pt-1 pb-1" />
                {PIX_OPTION.name}
              </ListGroup.Item>
            )}
            {mercadoPagoLogin && (
              <ListGroup.Item
                href="#"
                key="mercado_pago"
                onClick={() => onPaymentSelect(MERCADO_PAGO_OPTION)}
                className="mt-3 d-flex flex-row align-items-center justify-content-between cursor-pointer"
              >
                <img
                  src={mpURL}
                  alt="Mercado Pago"
                  height="70"
                  className="ml-5"
                />
                {MERCADO_PAGO_OPTION.name}
              </ListGroup.Item>
            )}
            {mercadoPagoCartao &&
              cards.map((card) => (
                <ListGroup.Item
                  href="#"
                  key={card.id}
                  onClick={() => onCardSelection(card)}
                  className="mt-3 d-flex flex-row align-items-center justify-content-between cursor-pointer"
                >
                  <div className="d-flex flex-row align-items-center">
                    <PaymentCardImage
                      paymentLabel={card.issuer_name}
                      className="mr-4"
                    />
                    <div>
                      {card.issuer_name}
                      <br />
                      {`**** ${card.last_four_digits}`}
                    </div>
                  </div>
                  <Button
                    variant="link"
                    onClick={(event) => showDeleteCard(event, card)}
                  >
                    <DeleteForeverIcon />
                  </Button>
                </ListGroup.Item>
              ))}
          </ListGroup>
          {mercadoPagoCartao && (
            <Button
              className="w-100 mt-3"
              variant="danger"
              type="submit"
              onClick={addNewCard}
            >
              Adicionar novo cartão de crédito
            </Button>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={isCardFormVisible} onHide={cancelCardForm}>
        <Modal.Header closeButton>
          <Modal.Title as="h3">Adicionar cartão de crédito</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CardMercadoPago onSuccess={cancelCardForm} />
        </Modal.Body>
      </Modal>

      <Modal show={!!cardToDelete} onHide={cancelDeleteCard}>
        <Modal.Header closeButton>
          <Modal.Title>Quer excluir este cartão?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {cardToDelete &&
            `Ao confirmar, o cartão ${cardToDelete.issuer_name} final ${cardToDelete.last_four_digits} não estará mais disponível na sua lista de cartões salvos no aplicativo.`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={cancelDeleteCard}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={onRemoveCard}>
            Excluir cartão
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
