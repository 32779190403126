import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';

export default function ErrorWall({ title = null, children = null }) {
  return (
    <div className="d-flex flex-column w-100 vh-100 align-items-center justify-content-center">
      <Alert variant="danger">
        <Container>
          <Alert.Heading>{title || 'Ops... Erro ao carregar!'}</Alert.Heading>
          {children !== null ? (
            children
           ) : (
            <>
              <p>
                Os dados dessa página não puderam ser encontrados.
              </p>
              {window.location.search.includes('in-loco=true') ? (
                <p>
                  Consulte com o restaurante uma URL correta para sua mesa.
                </p>
              ) : (
                <p>
                  É possível alguém ter digitado a URL manualmente e veio com algum
                  erro de digitação.
                </p>
              )}
            </>
          )}
        </Container>
      </Alert>
    </div>
  );
}
