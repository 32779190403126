import React from 'react';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { makeStyles } from '@material-ui/core/styles';
import { WHATSAPP_CHAT_URL } from '../ducks/consts';

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: '5px',
    padding: '10px',
    color: '#fff !important',
    background: '#01e675',
  },
}));

function WhatsAppButton({whatsapp, message}) {
    const classes = useStyles();

    if (!whatsapp) {
        return null;
    }

    const encodedMessage = message ? encodeURI(message) : '';
    const whatsAppUrl = `${WHATSAPP_CHAT_URL}/55${whatsapp}?text=${encodedMessage}`;

    return (
        <div className="mt-3 mb-3">
            <a rel="noopener noreferrer" target="_blank" href={whatsAppUrl} className={classes.button}>
                <WhatsAppIcon className="mr-2" />
                WhatsApp
            </a>
        </div>
    );
}

export default WhatsAppButton;